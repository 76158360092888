import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';
import { formatDateInput, formatDateTimeInput, showToast } from 'helpers/util';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';

const CreateUpdateAvailability = ({ data, toggle, modal, handleCreateModal, modalDate, userId, setSchedules, refetch }) => {
  const [errors, setErrors] = useState([])

  const { getValues, setValue, handleSubmit, watch, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data?.id : null,
      start: data ? data?.start : modalDate,
    }
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => data?.id ? api.updateAvailability(params) : api.addNewAvailability(params), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        refetch()
        // !data.id && setSchedules(prev => [...prev, {...res?.event, start: formatDateTimeInput(res?.event.start)}])
        toggle()
      } else {
        setErrors(res.errors)
      }
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit availability', 'error')
    }
  });

  const { mutate: deleteAvailability, isLoading: deleteLoading } = useMutation(() => api.deleteAvailability(data?.id), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        refetch()
        // !data.id && setSchedules(prev => [...prev, {...res?.event, start: formatDateTimeInput(res?.event.start)}])
        toggle()
      } else {
        setErrors(res.errors)
      }
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit availability', 'error')
    }
  });

  const onSubmit = () => {
    mutate({
      id: data?.id,
      user_id: userId,
      title: getValues('title'),
      start: getValues('start'),
      end: getValues('end'),
      class: getValues('class'),
    })
  };

  useEffect(() => {
    if (data?.id) {
      setValue('id', data.id)
      setValue('title', data.title)
      setValue('start', formatDateTimeInput(data.start))
    } else {
      setValue('id', null)
      setValue('start', data ? formatDateTimeInput(data?.start) : formatDateTimeInput(modalDate))
    }
    reset({
      ...data,
      start: data ? formatDateTimeInput(data?.start) : formatDateTimeInput(modalDate),
      class: data?.class,
    } ?? {});
  }, [data, reset, setValue])

  const start = watch('start');

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data?.id ? 'Update Availability' : 'Create Availability'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}>
          {(data?.title == 'Day Off' || data?.title == 'Annual Leave' || data?.title == 'Sick Leave') ? (
            <>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-title">Title</Label>
                    <select
                      id="formrow-title"
                      className="form-control"
                      defaultValue={data?.title}
                      onChange={(e) => setValue('title', e.target.value)}
                    >
                      <option value="">Choose...</option>
                      <option value="Working">Working</option>
                      <option value="Day Off">Day Off</option>
                      <option value="Sick Leave">Sick Leave</option>
                      <option value="Annual Leave">Annual Leave</option>
                    </select>
                    {errors.title && <span className="form-text text-danger">{errors.title[0]}</span>}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-InputDate">Date</Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="formrow-InputDate"
                      placeholder="Date Time"
                      defaultValue={formatDateInput(data ? data?.start : modalDate)}
                      onInput={(e) => setValue('start', e.target.value)}
                    />
                    {errors.start && <span className="form-text text-danger">{errors.start[0]}</span>}
                  </div>
                </Col>
              </Row>
              <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputMessage">Message</Label>
                      <textarea
                        className="form-control"
                        id="formrow-InputMessage"
                        rows={5}
                        onInput={(e) => setValue('message', e.target.value)}
                      >{data?.message}</textarea>
                      {errors.start && <span className="form-text text-danger">{errors.start[0]}</span>}
                    </div>
                  </Col>
                </Row>
              </>
          ) : (
            <>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-title">Title</Label>
                    <select
                      id="formrow-title"
                      className="form-control"
                      defaultValue={data?.title}
                      onChange={(e) => setValue('title', e.target.value)}
                    >
                        <option value="">Choose...</option>
                        <option value="Present">Present</option>
                        <option value="Working">Working</option>
                        <option value="Day Off">Day Off</option>
                        <option value="Personal Day">Personal Day</option>
                        <option value="Sick Leave">Sick Leave</option>
                        <option value="Annual Leave">Annual Leave</option>
                    </select>
                    {errors.title && <span className="form-text text-danger">{errors.title[0]}</span>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-InputDate">Start</Label>
                    <Input
                      type="datetime-local"
                      className="form-control"
                      id="formrow-InputDate"
                      placeholder="Date Time"
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                      max="9999-12-31T23:59"
                      defaultValue={formatDateTimeInput(data ? data?.start : modalDate)}
                      onInput={(e) => setValue('start', e.target.value)}
                    />
                    {errors.start && <span className="form-text text-danger">{errors.start[0]}</span>}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-InputDate">End</Label>
                    <Input
                      type="datetime-local"
                      className="form-control"
                      id="formrow-InputDate"
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                      max="9999-12-31T23:59"
                      defaultValue={formatDateTimeInput(data ? data?.end : null)}
                      onInput={(e) => setValue('end', e.target.value)}
                      min={start}
                    />
                    {errors.start && <span className="form-text text-danger">{errors.start[0]}</span>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-InputMessage">Message</Label>
                    <textarea
                      className="form-control"
                      id="formrow-InputMessage"
                      rows={5}
                      onInput={(e) => setValue('message', e.target.value)}
                    >{data?.message}</textarea>
                    {errors.start && <span className="form-text text-danger">{errors.start[0]}</span>}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </div>
      <div className="modal-footer d-md-flex justify-content-md-between">
        <div className="d-md-flex justify-content-md-end">
          {data?.id && (
            <button type="submit" className="btn btn-danger w-md" disabled={deleteLoading} onClick={(e) => {
              e.preventDefault()
              deleteAvailability();
            }}>
              {deleteLoading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Delete</span>
              )}
            </button>
          )}
        </div>
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary w-md" disabled={submitLoading} onClick={(e) => {
            e.preventDefault()
            onSubmit()
          }}>
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateAvailability.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  handleCreateModal: PropTypes.func,
  type: PropTypes.string,
  modalDate: PropTypes.any,
  userId: PropTypes.number,
  setSchedules: PropTypes.func,
  refetch: PropTypes.func,
};

export default CreateUpdateAvailability;