import React, { useEffect, useState } from "react"
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import {
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"

const GeneralSetting = ({ data, refetch, errors }) => {
  const { setValue } = useFormContext();

  return (
    <>
      <Row>
        <Col md={4}>
          <div className="mb-3">
            <Label>Email Notification</Label>
            <Input
              type="email"
              className="form-control"
              placeholder="Email Notification"
              onInput={(e) => setValue('email_notification', e.target.value)}
              defaultValue={data?.email_notification}
            />
            {errors?.email_notification && <span className="form-text text-danger">{errors?.email_notification[0]}</span>}
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Label>Phone Notification</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Phone Notification"
              onInput={(e) => setValue('phone_notification', e.target.value)}
              defaultValue={data?.phone_notification}
            />
            {errors?.phone_notification && <span className="form-text text-danger">{errors?.phone_notification[0]}</span>}
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Label>Company Address</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Company Address"
              onInput={(e) => setValue('company_address', e.target.value)}
              defaultValue={data?.company_address}
            />
            {errors?.company_address && <span className="form-text text-danger">{errors?.company_address[0]}</span>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="mb-3">
            <Label>Twillio SID</Label>
            <Input
              type="email"
              className="form-control"
              placeholder="Twillio SID"
              onInput={(e) => setValue('twillio_sid', e.target.value)}
              defaultValue={data?.twillio_sid}
            />
            {errors?.twillio_sid && <span className="form-text text-danger">{errors?.twillio_sid[0]}</span>}
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Label>Twillio Token</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Twillio Token"
              onInput={(e) => setValue('twillio_token', e.target.value)}
              defaultValue={data?.twillio_token}
            />
            {errors?.twillio_token && <span className="form-text text-danger">{errors?.twillio_token[0]}</span>}
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <Label>Twillio Msg ID</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Twillio Msg ID"
              onInput={(e) => setValue('twillio_msg_id', e.target.value)}
              defaultValue={data?.twillio_msg_id}
            />
            {errors?.twillio_msg_id && <span className="form-text text-danger">{errors?.twillio_msg_id[0]}</span>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label>Stripe Private Key</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Stripe Private Key"
              onInput={(e) => setValue('stripe_pk', e.target.value)}
              defaultValue={data?.stripe_pk}
            />
            {errors?.stripe_pk && <span className="form-text text-danger">{errors?.stripe_pk[0]}</span>}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label>Stripe Secret Key</Label>
            <Input
              type="password"
              className="form-control"
              placeholder="Stripe Secret Key"
              onInput={(e) => setValue('stripe_sk', e.target.value)}
              defaultValue={data?.stripe_sk}
            />
            {errors?.stripe_sk && <span className="form-text text-danger">{errors?.stripe_sk[0]}</span>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label>Logo</Label>
            <Input
              type="file"
              className="form-control"
              placeholder="Logo"
              onChange={(e) => setValue('logo', e.target.files[0])}
            />
            {errors?.logo && <span className="form-text text-danger">{errors?.logo[0]}</span>}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label>Manual</Label>
            <Input
              type="password"
              className="form-control"
              placeholder="Manual"
              onInput={(e) => setValue('manual', e.target.value)}
              defaultValue={data?.manual}
            />
            {errors?.manual && <span className="form-text text-danger">{errors?.manual[0]}</span>}
          </div>
        </Col>
      </Row>
    </>
  )
}

GeneralSetting.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  errors: PropTypes.object,
};

export default React.memo(GeneralSetting);