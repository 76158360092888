import { useMutation, useQuery } from '@tanstack/react-query'
import api from 'helpers/api'
import { formatDateTime, showToast } from 'helpers/util'
import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { Pagination } from 'react-laravel-paginex'
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledButtonDropdown } from 'reactstrap'

const FailedBroadcast = () => {
  const [page, setPage] = useState(1)
  const { data, refetch: getData, isLoading } = useQuery(['failed-broadcast', page], () => api.getFailedBroadcast({
    params: {
      page: page,
    }
  }), {
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => {
    return api.resendFailedBroadcast(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      getData()
    },
    onError: (err) => {
      showToast('Failed to resend broadcast', 'error')
    }
  })

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: data?.failed_broadcasts.total, // replace later with size(doctors),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "id",
      doctor: "desc",
      order: 'desc',
    },
  ];

  const EcommerceBroadcastColumns = toggleModal => [
    {
      dataField: "broadcast.subject",
      text: "Subject",
      sort: true,
    },
    {
      dataField: "user.fullname",
      text: "Name",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Failed At",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => formatDateTime(row.created_at),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Button color="warning" size="sm" onClick={() => mutate(row.id)}>Resend</Button>
        // <>
        //   <UncontrolledButtonDropdown direction="start">
        //     <DropdownToggle caret>
        //       Action
        //     </DropdownToggle>
        //     <DropdownMenu>
        //       <>
        //         <DropdownItem onClick={() => {
        //           // setId(row.id);
        //           // setShowDelete(true);
        //         }}>
        //           Resend
        //         </DropdownItem>
        //       </>
        //     </DropdownMenu>
        //   </UncontrolledButtonDropdown>
        // </>
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner className="ms-2 spinner-loading" color="success" />
      ) : (
        <Row>
          <Col sm="12">
            {data && (
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={EcommerceBroadcastColumns()}
                data={data.failed_broadcasts.data}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={data.failed_broadcasts.data}
                    columns={EcommerceBroadcastColumns()}
                    bootstrap4
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <Pagination options={{ numberButtonClass: "page-item d-flex" }} changePage={(e) => setPage(e.page)} data={data.failed_broadcasts} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

export default React.memo(FailedBroadcast)