import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";

import { map } from "lodash";

// Import Editor
import { Editor } from "react-draft-wysiwyg";

import {
  getSentMails as onGetSentMails,
} from "store/mails/actions";

//Import Email Topbar
// import EmailToolbar from "./email-toolbar";
//redux
import { useMutation, useQuery } from "@tanstack/react-query";
import { convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import api from "helpers/api";
import { formatDateTime, showToast } from "helpers/util";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Preview from "./Preview";
import FailedBroadcast from "./component/FailedBroadcast";

const Broadcast = props => {
  const [activeTab, setactiveTab] = useState("1");
  const [modal, setmodal] = useState(false);
  const [errors, setErrors] = useState([])
  const [showPreview, setShowPreview] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [searchPatient, setSearchPatient] = useState(null)
  const [searchGp, setSearchGp] = useState(null)
  const [searchDoctor, setSearchDoctor] = useState("")
  const dispatch = useDispatch()
  const { getValues, setValue, handleSubmit, reset, watch, formState: { formErrors } } = useForm();
  const { data: patientData, loadingPatient } = useQuery(['get-patient', searchPatient], () => api.getSelectPatient({
    params: {
      search: searchPatient,
    }
  }), {
    refetchOnWindowFocus: false
  });

  const { data: gp, isLoading: loadingGpSite } = useQuery(['get-gp', searchGp], () => api.getSelectGp({
    params: {
      search: searchGp,
    }
  }), {
    refetchOnWindowFocus: false
  });

  const { data: doctors, isLoading: doctorLoading } = useQuery(
    ["select-doctor", searchDoctor],
    () =>
      api.getSelectDoctor({
        params: {
          search: searchDoctor,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { mutate } = useMutation(() => api.sendBroadcasts(getValues()), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        dispatch(onGetSentMails());
        setmodal(false)
      } else {
        if (res.errors) {
          setErrors(res.errors)
          showToast(res.message, 'error')
        }else{
          showToast(res.message, 'error')
        }
      }
    },
    refetchOnWindowFocus: false
  });

  const togglePreview = () => {
    if (showPreview) {
      setShowPreview(false);
    } else {
      setShowPreview(true);
    }
  };

  const handlePreview = (data) => {
    togglePreview();
    setModalData(data)
  };

  const {
    sentmails,
  } = useSelector(state => ({
    sentmails: state.mails.sentmails.data,
  }));

  useEffect(() => {
    dispatch(onGetSentMails());
  }, [dispatch]);

  const broadcast_type = watch('broadcast_type')
  const type = watch('type')
  const userType = watch('userType')

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Email" breadcrumbItem="Inbox" />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              <Card className="email-leftbar">
                <Button
                  type="button"
                  color="danger"

                  onClick={() => {
                    setmodal(!modal);
                  }}
                  block
                >
                  Compose
                </Button>
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          setactiveTab("1");
                        }}
                      >
                        <i className="mdi mdi-email-check-outline me-2"></i>Sent
                        Mail
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          setactiveTab("2");
                        }}
                      >
                        <i className="mdi mdi-email-remove-outline me-2"></i>Failed Mail
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <h6 className="mt-4">Labels</h6>

                <div className="mail-list mt-1">
                  <Link to="#">
                    <span className="mdi mdi-arrow-right-drop-circle text-warning float-end"></span>
                    Patient
                  </Link>
                  <Link to="#">
                    <span className="mdi mdi-arrow-right-drop-circle text-info float-end"></span>
                    GP
                  </Link>
                  <Link to="#">
                    <span className="mdi mdi-arrow-right-drop-circle text-primary float-end"></span>
                    App
                  </Link>
                </div>
              </Card>

              <Modal
                isOpen={modal}
                size="lg"
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                tabIndex="-1"
                toggle={() => {
                  setmodal(!modal);
                }}
              >
                <div className="modal-content">
                  <ModalHeader
                    toggle={() => {
                      setmodal(!modal);
                    }}
                  >
                    New Message
                  </ModalHeader>
                  <ModalBody>
                    <form>
                      <div className="row">
                        <div className={`col`}>
                          <div className="mb-3">
                            <Label>Select Receiver</Label>
                            <select
                              id="formrow-type"
                              className="form-control"
                              onChange={(e) => setValue('type', e.target.value)}
                            >
                              <option value="">Choose Receiver...</option>
                              <option value="GP">To GP</option>
                              <option value="Patient">To Patient</option>
                              <option value="DoctorPatient">To All Patient From Selected Doctor</option>
                              <option value="App">App Notification</option>
                              <option value="Custom">Custom</option>
                            </select>
                            {errors?.type && <span className="form-text text-danger">{errors?.type[0]}</span>}
                          </div>
                        </div>

                        {type == 'DoctorPatient' ? (
                          <div className={`col`}>
                            <div className="mb-3">
                              <Label>Doctor</Label>
                              <Select
                                options={doctors}
                                onInputChange={(e) => setSearchDoctor(e)}
                                onChange={(e) => setValue('doctor_id', e.value)}
                                isLoading={doctorLoading}
                                classNamePrefix="select2-selection"
                              />
                              {errors?.doctor_id && <span className="form-text text-danger">{errors?.doctor_id[0]}</span>}
                            </div>
                          </div>
                        ) : null}

                        {type == 'Custom' ? (
                          <div className={`col`}>
                            <div className="mb-3">
                              <Label>Type</Label>
                              <select
                                id="formrow-type"
                                className="form-control"
                                onChange={(e) => setValue('userType', e.target.value)}
                              >
                                <option value="">Choose Type...</option>
                                <option value="GP">GP</option>
                                <option value="Patient">Patient</option>
                              </select>
                              {errors?.userType && <span className="form-text text-danger">{errors?.userType[0]}</span>}
                            </div>
                          </div>
                        ) : null}

                        {type == 'Custom' ? (
                          <div className={`col`}>
                            <div className="mb-3">
                              <Label>Receiver</Label>
                              <Select
                                options={userType == 'GP' ? gp : patientData}
                                onInputChange={(e) => userType == 'GP' ? setSearchGp(e) : setSearchPatient(e)}
                                onChange={(e) => setValue('include_user_ids', e.map(val => val.value))}
                                isLoading={userType == 'GP' ? loadingGpSite : loadingPatient}
                                classNamePrefix="select2-selection"
                                isMulti
                              />
                              {errors?.include_user_ids && <span className="form-text text-danger">{errors?.include_user_ids[0]}</span>}
                            </div>
                          </div>
                        ) : type != 'DoctorPatient' ? (
                          <div className={`col`}>
                            <div className="mb-3">
                              <Label>Exclude Receiver</Label>
                              <Select
                                options={type == 'GP' ? gp : patientData}
                                onInputChange={(e) => type == 'GP' ? setSearchGp(e) : setSearchPatient(e)}
                                onChange={(e) => setValue('exclude_user_ids', e.map(val => val.value))}
                                isLoading={type == 'GP' ? loadingGpSite : loadingPatient}
                                classNamePrefix="select2-selection"
                                isMulti
                              />
                              {errors?.exclude_user_ids && <span className="form-text text-danger">{errors?.exclude_user_ids[0]}</span>}
                            </div>
                          </div>
                        ) : null}
                        {type != 'App' ? (
                          <div className={`col`}>
                            <div className="mb-3">
                              <Label>Select Broadcast Type</Label>
                              <select
                                id="formrow-type"
                                className="form-control"
                                onChange={(e) => setValue('broadcast_type', e.target.value)}
                              >
                                <option value="">Choose Broadcast Type...</option>
                                <option value="Email">Email</option>
                                <option value="SMS">SMS</option>
                              </select>
                              {errors?.broadcast_type && <span className="form-text text-danger">{errors?.broadcast_type[0]}</span>}
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {broadcast_type == 'Email' ? (
                        <div className="mb-3">
                          <Label>Subject</Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            onInput={(e) => setValue('subject', e.target.value)}
                          />
                          {errors?.subject && <span className="form-text text-danger">{errors?.subject[0]}</span>}
                        </div>
                      ) : null}
                      <Label>Message</Label>
                      {broadcast_type == 'Email' ? (
                        <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) => setValue('text', draftToHtml(convertToRaw(e.getCurrentContent())))}
                        />
                      ) : (
                        <textarea className="form-control" rows={5} onInput={(e) => setValue('text', e.target.value)} placeholder="Message"></textarea>
                      )}
                      {errors?.text && <span className="form-text text-danger">{errors?.text[0]}</span>}
                    </form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => {
                        setmodal(!modal);
                      }}
                    >
                      Close
                    </Button>
                    <Button type="button" color="primary" onClick={mutate}>
                      Send <i className="fab fa-telegram-plane ms-1"></i>
                    </Button>
                  </ModalFooter>
                </div>
              </Modal>
              <div className="email-rightbar mb-3">
                <Card>
                  {/* Render Email Top Tool Bar */}
                  {/* <EmailToolbar /> */}
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <ul className="message-list">
                        <li>
                          <div className="col-mail col-mail-1">
                            <b style={{ marginTop: 15, marginRight: 10, marginLeft: 20 }}>#</b>
                            <b style={{position: 'absolute', left: 110}}>Subject</b>
                            {/* <span className="star-toggle far fa-star" /> */}
                          </div>
                          <div className="col-mail col-mail-2">
                            <b style={{position: 'absolute', left: 0, right: 200}}>Message</b>
                            <b style={{position: 'absolute', right: 0, paddingRight: 80}}>Send At</b>
                          </div>
                        </li>
                        {map(sentmails, (sent, key) => (
                          <li
                            key={"sent-" + key}
                            onClick={() => handlePreview(sent)}
                            // className={sent.read ? "" : "unread"}
                          >
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input type="checkbox" id={sent.id + "sent"} />
                                <Label
                                  htmlFor={sent.id + "sent"}
                                  className="toggle"
                                />
                              </div>
                              <Link to="#" className="title">
                                {sent.subject ?? sent.type}
                              </Link>
                              {/* <span className="star-toggle far fa-star" /> */}
                            </div>
                            <div className="col-mail col-mail-2">
                              <div>
                                <Link to="#" className="subject">
                                  <span className={`bg-${sent.type == 'Patient' ? 'warning' : sent.type == 'GP' ? 'info' : 'primary' } badge me-2`}> {sent.type} </span>
                                  {sent.text.replace(/(<([^>]+)>)/ig, '').replaceAll(' ', '\u00a0').replaceAll('&nbsp;', '')}
                                  {/* <div
                                    dangerouslySetInnerHTML={{
                                      __html: sent.text,
                                    }}
                                  ></div> */}
                                </Link>
                              </div>
                              <div className="date">{formatDateTime(sent.created_at)}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </TabPane>
                    <TabPane tabId="2">
                      <FailedBroadcast />
                    </TabPane>
                  </TabContent>
                </Card>
                {/* <Row>
                  <Col xs="7">Showing 1 - 20 of 1,524</Col>
                  <Col xs="5">
                    <div className="btn-group float-end">
                      <Button
                        type="button"
                        color="success"
                        size="sm"

                      >
                        <i className="fa fa-chevron-left" />
                      </Button>
                      <Button
                        type="button"
                        color="success"
                        size="sm"

                      >
                        <i className="fa fa-chevron-right" />
                      </Button>
                    </div>
                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>
          <Preview modal={showPreview} toggle={() => setShowPreview(false)} handleCreateModal={handlePreview} data={modalData} />

        </Container>
      </div>
    </React.Fragment>
  );
};

Broadcast.propTypes = {
  inboxmails: PropTypes.array,
  starredmails: PropTypes.array,
  onGetInboxMails: PropTypes.func,
  onGetStarredMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
};

export default withRouter(Broadcast);
