import PropTypes from "prop-types";
import React, { useState } from "react";
import MetaTags from "react-meta-tags";

import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import { Link, withRouter } from "react-router-dom";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

//Social Media Imports
// import TwitterLogin from "react-twitter-auth"

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import logo from "assets/images/logo.svg";
import profile from "assets/images/profile-img.png";

//Import config
import { axiosApi, post } from "helpers/api_helper";
import { showToast } from "helpers/util";

const Login = props => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [modal, setModal] = useState(true)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: email,
      password: password,
      login_method: 'WEBSITE',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      const res = await post('/login', values);
      showToast(res.message, !res.status && 'error')
      await localStorage.setItem("authUser", JSON.stringify(res))
      const { token } = await JSON.parse(localStorage.getItem('authUser')) || {};
      axiosApi.defaults.headers.common["Authorization"] = token && `Bearer ${token}`

      res.status && dispatch(loginUser(res, props.history));
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history));
  };

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook");
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | ADHD Centre</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to MyAdhdCentre.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={e => setEmail(e.target.value)}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={e => setPassword(e.target.value)}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          <li className="list-inline-item">
                           <TwitterLogin
                             loginUrl={
                               "http://localhost:4000/api/v1/auth/twitter"
                             }
                             onSuccess={this.twitterResponse}
                             onFailure={this.onFailure}
                             requestTokenUrl={
                               "http://localhost:4000/api/v1/auth/twitter/revers"
                             }
                             showIcon={false}
                             tag={"div"}
                           >
                             <a
                               href=""
                               className="social-list-item bg-info text-white border-info"
                             >
                               <i className="mdi mdi-twitter"/>
                             </a>
                           </TwitterLogin>
                          </li>
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => { }}
                            />
                          </li>
                        </ul>
                      </div> */}

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} MyAdhdCentre. Design & develop by Xpro Group Pty Ltd
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="lg"
        toggle={() => setModal(modal ? false : true)}
        isOpen={modal}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            FEE & CLINIC POLICY
          </h5>
          <button
            onClick={() => setModal(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="pum-content popmake-content" tabIndex="0">
            <h4 style={{ textAlign: 'center' }} className="mt-n3">SIAC FEES</h4>
            <h5 className="mt-4">Important Notice: Changes to Our Email Address</h5>
            <p>Effective Friday, 9 February 2024 (AEDT), all of our official communication will be from <b>info@myadhdaustralia.com.au</b>.</p>
            <p>If you encounter any issues during this process or have any questions, please feel free to contact our customer support at (02) 9870 0252. Our team is available from Monday to Friday between 9 am to 5 pm (AEDT) to assist you and ensure a smooth transition.</p>
            <h5>Sydney International ADHD Centre:</h5>
            <p>The SIAC is a private practice that charges private fees for its services.</p>
            <p>Unfortunately, we do not offer any bulk-billing services at our clinic. If you are experiencing <br /> any
              financial hardship, please discuss options with your GP. </p>
            <p>Medicare rebate:</p>
            <p>All initial patients are required to bring a referral letter from their GP or from a Medical Specialist at their
              first appointment in order to be eligible for a Medicare rebate.</p>
            <p>Referrals from a GP and from a Specialist must be renewed every 12 months and 3 months, respectively; before they
              lapse.</p>
            <h5>Consultations:</h5>
            <p className="p1">Initial Consultation (Up to 1hr):&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              $850 (Medicare rebate: $237)</p>
            <p className="p1">Follow Up Appointment (Less Than 6 Months):&nbsp;&nbsp; $450 (Medicare rebate: $121.50)</p>
            <p className="p1">Follow Up Appointment (More than 6 Months):&nbsp;&nbsp; $550 (Medicare rebate: $167)</p>
            <p>&nbsp;</p>
            <h5 className="mt-n5">Other Services:</h5>
            <p>Standard Certificate:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;$100 + GST</p>
            <p>Any Support letters under 2 pages:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; $200 + GST Per Page</p>
            <p>Medical Reports:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;$350 + GST per
              page</p>
            {/* <p>Script out of session 2-3 registered post included $65 24hrs processing $85
              Standard Medical cert $80</p> */}
            <h4 style={{ textAlign: 'center' }} className="mt-n2">CLINIC POLICY</h4>
            <h5>Deposit &amp; Cancellation Policy</h5>
            <p>The goal of SIAC is to deliver an excellent quality healthcare to all of our patients. When an appointment is
              scheduled for you, that time has been set aside for you and when it is missed, that time cannot be used to treat
              other patients.</p>
            <p>Please respect our doctor’s time and give us adequate notice so that we are able to reallocate that time to other
              patients. In case of non-attendance or cancellation less than two business days from day of
              consultation&nbsp;the deposit will be forfeited&nbsp;as the clinic may be unable to reallocate the time slot to
              another patient.</p>
            <h5>Private patients</h5>
            <p>At the time of booking, a 50% deposit is required to secure your appointment (for both Initial &amp;amp; Follow
              up appointments), this deposit will be used towards your appointment.</p>
            <p>For Telehealth consultation, a full consultation fee is required to secure your appointment. The payment is fully
              refundable if you cancel or reschedule your appointment with at least 2 full business days’ notice; otherwise,
              the 50% deposit will be forfeited.</p>
            <h5>Worker’s Compensation &amp; Ctp Patients</h5>
            <p>At the time of booking, a deposit as stated below is required to secure your appointment. <br /> – $300 for Doctor’s initial appointment; <br /> –&nbsp;$150 for Doctor’s Follow Up Appointment </p>
            <h5>Patients with two late cancellation or no-show records</h5>
            <p>Patients who have a late cancellations or no-show records will be required to prepay&nbsp;their consultation fee
              in full for all upcoming appointments thereafter.</p>
            <h5>Late Arrival Policy</h5>
            <p>SIAC wish to provide a memorable experience for all patients when visiting the centre or Telehealth from home by
              reducing wait times. As such, we implement a late arrival policy to ensure patients are on time receive their
              full allocation of time.</p>
            <p>If patients arrive less than 15 minutes late, their appointment will be shortened to the remaining time and
              charged in full. If patients arrive more than 15 minutes late, the patient will not be seen due to time
              constraints. The appointment will be considered as “no-show” and the associated fee will be charged.</p>
            <p>We recommend you getting ready or arrive at the clinic 10 minutes prior to your appointment.</p>
            <h5>Doctor communication policy</h5>
            <p>To avoid disruption, phone calls from patients will not be put through to the doctors whilst they are seeing
              other patients.</p>
            <p>Please understand that your doctor could not remember everyone’s case without reviewing your file, therefore all
              enquiries about medication, symptoms, prescriptions, medical reports, medical certificate, application forms
              will not be <br /> responded to by our doctors via phone or email. Patients are required to book an appointment to
              discuss these issues. Please contact us on 9870 0252 or email to info@myadhdcentre.com.au </p>
            <p>Please note that we are not a crisis management service. In case of emergency, please ring 000 or go to the
              Emergency Department at your local hospital or Mental Health Access Line 1800 011 511</p>
            <h5>Zero Tolerance Policy</h5>
            <p>We ask that you treat our reception team and clinical staff with mutual courtesy and respect. We have a
              zero-tolerance policy to any abusive, aggressive and threatening behaviour towards our staff. If violated, we
              reserve the right to terminate a phone call, ask the patient to leave or call the police.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            onClick={() => setModal(false)}
            type="button"
            className="btn btn-success"
            data-dismiss="modal"
            aria-label="Close"
          >
            I AGREE
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
