import { useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { currency } from 'helpers/util';
import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { MetaTags } from 'react-meta-tags'
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap'
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ReportIncome = () => {
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [series, setSeries] = useState([400, 430, 448])
  const [options, setOptions] = useState({
    labels: ["Paid Invoice", "Partially Invoice", 'Unpaid Invoice'],
    colors: ["#34c38f", "#f1b44c", "#f46a6a"],
    dataLabels: {
      enabled: true,
      offsetX: 20,
      offsetY: 20,
      formatter: function (val, opts) {
        console.log(opts.w.config.series[opts.seriesIndex])
        return currency(opts.w.config.series[opts.seriesIndex]) + `(${val.toFixed(2)}%)`
      },
      textAnchor: 'middle',
    },
    tooltip: {
      y: {
        show: true,
        formatter: function (val, opts) {
          return currency(val)
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
    title: {
      text: 'Income Report',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
  })

  const { data, refetch, isLoading } = useQuery(['get-report-income'], () => api.getReportIncome());

  const { data: chart, isLoading: loadingChart } = useQuery(['get-report-income-chart', from, to], () => api.getReportIncomeChart({
    params: {
      from,
      to,
    }
  }), {
    onSuccess: (res) => {
      setSeries([res.data.invoice_paid, res.data.invoice_partially, res.data.invoice_unpaid])
    },
    enabled: !!(from && to),
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ADHD Centre</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Report Income" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {isLoading ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    <>
                      <Row>
                        <Col md={4}>
                          <Link to="/invoices">
                            <Card body>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <h4 className="text-muted fw-large">
                                    Payment This Month
                                  </h4>
                                  <h2 className="mb-0">{currency(data?.data?.payment_monthly)}</h2>
                                </div>
                                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-success">
                                    <i className='bx bxs-right-arrow-circle font-size-24'></i>
                                  </span>
                                </div>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                        <Col md={4}>
                          <Link to="/invoices">
                            <Card body>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <h4 className="text-muted fw-large">
                                    Payment This Week
                                  </h4>
                                  <h2 className="mb-0">{currency(data?.data?.payment_weekly)}</h2>
                                </div>
                                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-success">
                                    <i className='bx bxs-right-arrow-circle font-size-24'></i>
                                  </span>
                                </div>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                        <Col md={4}>
                          <Link to="/invoices">
                            <Card body>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <h4 className="text-muted fw-large">
                                    Payment This Day
                                  </h4>
                                  <h2 className="mb-0">{currency(data?.data?.payment_today)}</h2>
                                </div>
                                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-success">
                                    <i className='bx bxs-right-arrow-circle font-size-24'></i>
                                  </span>
                                </div>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="2">
                          <label>From</label>
                          <input type="date" className="form-control" onChange={e => setFrom(e.target.valueAsDate)} />
                        </Col>
                        <Col md="2">
                          <label>To</label>
                          <input type="date" className="form-control" onChange={e => setTo(e.target.valueAsDate)} />
                        </Col>
                      </Row>
                      {from && to && !loadingChart ? (
                        <>
                          <Row>
                            <Col md={4}>
                              <Link to="/invoices">
                                <Card body>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <h4 className="text-muted fw-large">
                                        Invoice Paid
                                      </h4>
                                      <h2 className="mb-0">{currency(chart?.data?.invoice_paid)}</h2>
                                    </div>
                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                      <span className="avatar-title rounded-circle bg-success">
                                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                                      </span>
                                    </div>
                                  </div>
                                </Card>
                              </Link>
                            </Col>
                            <Col md={4}>
                              <Link to="/invoices">
                                <Card body>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <h4 className="text-muted fw-large">
                                        Invoice Partially
                                      </h4>
                                      <h2 className="mb-0">{currency(chart?.data?.invoice_partially)}</h2>
                                    </div>
                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                      <span className="avatar-title rounded-circle bg-success">
                                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                                      </span>
                                    </div>
                                  </div>
                                </Card>
                              </Link>
                            </Col>
                            <Col md={4}>
                              <Link to="/invoices">
                                <Card body>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <h4 className="text-muted fw-large">
                                        Invoice Unpaid
                                      </h4>
                                      <h2 className="mb-0">{currency(chart?.data?.invoice_unpaid)}</h2>
                                    </div>
                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                      <span className="avatar-title rounded-circle bg-success">
                                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                                      </span>
                                    </div>
                                  </div>
                                </Card>
                              </Link>
                            </Col>
                          </Row>
                        <Row>
                          <Col>
                            <div id="donut-chart">
                              <ReactApexChart
                                series={series}
                                options={options}
                                type="pie"
                                height={500}
                                className="apex-charts"
                              />
                            </div>
                          </Col>
                        </Row>
                        </>
                      ) : (
                        <span>Please Select Date</span>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default React.memo(ReportIncome)