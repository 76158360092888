import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { showToast } from 'helpers/util';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';

const CreateUpdateGeneralPractice = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { getValues, setValue, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data.id : null,
    }
  });
  const { mutate } = useMutation((params) => {
    return data ? api.updateGeneralPractice(params) : api.addGeneralPractice(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      setLoading(false)
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit practice contact', 'error')
    }
  })

  useEffect(() => {
    if (data) {
      setValue('id', data.id)
    }else{
      setValue('id', null)
    }
    reset(data ?? {});
    setErrors([])
  }, [data])

  const onSubmit = () => mutate(getValues());

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Practice Contact' : 'Create Practice Contact'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(onSubmit())
          }}>
          <Row>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-title">Title</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-title"
                  placeholder="Title"
                  defaultValue={data?.title}
                  onChange={(e) => setValue('title', e.target.value)}
                />
                {errors?.title && <span className="form-text text-danger">{errors?.title[0]}</span>}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-name">Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-name"
                  placeholder="Name"
                  defaultValue={data?.name}
                  onChange={(e) => setValue('name', e.target.value)}
                />
                {errors?.name && <span className="form-text text-danger">{errors?.name[0]}</span>}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-title">Greeting</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-title"
                  placeholder="Greeting"
                  defaultValue={data?.greeting}
                  onChange={(e) => setValue('greeting', e.target.value)}
                />
                {errors?.greeting && <span className="form-text text-danger">{errors?.greeting[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-practice-name">Practice Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-practice-name"
                  placeholder="Practice Name"
                  defaultValue={data?.practice_name}
                  onChange={(e) => setValue('practice_name', e.target.value)}
                />
                {errors?.practice_name && <span className="form-text text-danger">{errors?.practice_name[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-category">Category</Label>
                <select
                  id="formrow-category"
                  className="form-control"
                  defaultValue={data?.category}
                  onInput={(e) => setValue('category', e.target.value)}
                >
                  <option value="">Choose...</option>
                  <option value="Anaesthetics">Anaesthetics</option>
                  <option value="Gastroenterology">Gastroenterology</option>
                  <option value="General Practice">General Practice</option>
                  <option value="General Surgery">General Surgery</option>
                  <option value="Medical practice">Medical practice</option>
                  <option value="Paediatrics">Paediatrics</option>
                  <option value="Pathology provider">Pathology provider</option>
                  <option value="Psychiatry">Psychiatry</option>
                  <option value="Psychology">Psychology</option>
                </select>
                {errors?.category && <span className="form-text text-danger">{errors?.category[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-price">Email</Label>
                <Input
                  type="email"
                  className="form-control"
                  id="formrow-email"
                  placeholder="Email"
                  defaultValue={data?.email}
                  onChange={(e) => setValue('email', e.target.value)}
                />
                {errors?.email && <span className="form-text text-danger">{errors?.email[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-phone">Mobile Phone</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-phone"
                  placeholder="Mobile Phone"
                  defaultValue={data?.phone}
                  onChange={(e) => setValue('phone', e.target.value)}
                />
                {errors?.phone && <span className="form-text text-danger">{errors?.phone[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-ahphone">A/H Phone</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-ahphone"
                  placeholder="A/H Phone"
                  defaultValue={data?.ahphone}
                  onChange={(e) => setValue('ahphone', e.target.value)}
                />
                {errors?.ahphone && <span className="form-text text-danger">{errors?.ahphone[0]}</span>}
              </div>
            </Col>
          </Row>

          <hr />

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">Address Line 1</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-address"
                  placeholder="Address Line 1"
                  defaultValue={data?.address_1}
                  onChange={(e) => setValue('address_1', e.target.value)}
                />
                {errors?.address_1 && <span className="form-text text-danger">{errors?.address_1[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">Address Line 2</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-address"
                  placeholder="Address Line 2"
                  defaultValue={data?.address_2}
                  onChange={(e) => setValue('address_2', e.target.value)}
                />
                {errors?.address_2 && <span className="form-text text-danger">{errors?.address_2[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">City</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-address"
                  placeholder="City"
                  defaultValue={data?.city}
                  onChange={(e) => setValue('city', e.target.value)}
                />
                {errors?.city && <span className="form-text text-danger">{errors?.city[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">Postcode</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-address"
                  placeholder="Postcode"
                  defaultValue={data?.postcode}
                  onChange={(e) => setValue('postcode', e.target.value)}
                />
                {errors?.postcode && <span className="form-text text-danger">{errors?.postcode[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-telephone">Telephone</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-telephone"
                  placeholder="Telephone"
                  defaultValue={data?.telephone}
                  onChange={(e) => setValue('telephone', e.target.value)}
                />
                {errors?.telephone && <span className="form-text text-danger">{errors?.telephone[0]}</span>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-fax">Fax</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-fax"
                  placeholder="Fax"
                  defaultValue={data?.fax}
                  onChange={(e) => setValue('fax', e.target.value)}
                />
                {errors?.fax && <span className="form-text text-danger">{errors?.fax[0]}</span>}
              </div>
            </Col>
          </Row>

          <hr />

          <Row>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-name">Provider No</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-name"
                  placeholder="Provider No"
                  defaultValue={data?.provider_no}
                  onChange={(e) => setValue('provider_no', e.target.value)}
                />
                {errors?.provider_no && <span className="form-text text-danger">{errors?.provider_no[0]}</span>}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-title">Health Identifier</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-title"
                  placeholder="Health Identifier"
                  defaultValue={data?.health_identifier}
                  onChange={(e) => setValue('health_identifier', e.target.value)}
                />
                {errors?.health_identifier && <span className="form-text text-danger">{errors?.health_identifier[0]}</span>}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-practice">PKI Key ID</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-practice"
                  placeholder="PKI Key ID"
                  defaultValue={data?.pki_key_id}
                  onChange={(e) => setValue('pki_key_id', e.target.value)}
                />
                {errors?.pki_key_id && <span className="form-text text-danger">{errors?.pki_key_id[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-name">Skype</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-name"
                  placeholder="Skype"
                  defaultValue={data?.skype}
                  onChange={(e) => setValue('skype', e.target.value)}
                />
                {errors?.skype && <span className="form-text text-danger">{errors?.skype[0]}</span>}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-title">Messaging Provider</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-title"
                  placeholder="Messaging Provider"
                  defaultValue={data?.messaging_provider}
                  onChange={(e) => setValue('messaging_provider', e.target.value)}
                />
                {errors?.messaging_provider && <span className="form-text text-danger">{errors?.messaging_provider[0]}</span>}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-practice">Account ID</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-practice"
                  placeholder="Account ID"
                  defaultValue={data?.account_id}
                  onChange={(e) => setValue('account_id', e.target.value)}
                />
                {errors?.account_id && <span className="form-text text-danger">{errors?.account_id[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-name">Website</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-name"
                  placeholder="Website"
                  defaultValue={data?.website}
                  onChange={(e) => setValue('website', e.target.value)}
                />
                {errors?.website && <span className="form-text text-danger">{errors?.website[0]}</span>}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-title">School ID</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-title"
                  placeholder="School ID"
                  defaultValue={data?.school_id}
                  onChange={(e) => setValue('school_id', e.target.value)}
                />
                {errors?.school_id && <span className="form-text text-danger">{errors?.school_id[0]}</span>}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-practice">Pager</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-practice"
                  placeholder="Pager"
                  defaultValue={data?.pager}
                  onChange={(e) => setValue('pager', e.target.value)}
                />
                {errors?.pager && <span className="form-text text-danger">{errors?.pager[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-practice">Comment</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-practice"
                  placeholder="Comment"
                  defaultValue={data?.comment}
                  onChange={(e) => setValue('comment', e.target.value)}
                />
                {errors?.comment && <span className="form-text text-danger">{errors?.comment[0]}</span>}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary w-md" disabled={loading} onClick={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit())
          }}>
            {loading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateGeneralPractice.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default React.memo(CreateUpdateGeneralPractice);