import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { formatDateTimeInput, showToast } from 'helpers/util';
import api from 'helpers/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

const CreateUpdateAppointment = ({ data, toggle, modal, handleCreateModal, refresh, defaultPatient = null }) => {
  const [searchDoctor, setSearchDoctor] = useState('')
  const [searchPatient, setSearchPatient] = useState('')
  const [errors, setErrors] = useState([])

  const { getValues, setValue, handleSubmit, watch, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data.id : null,
    }
  });

  const { data: doctorData, loadingDoctor, refetch: fetchDoctor } = useQuery(['get-doctor', searchDoctor], () => api.getSelectDoctor({
    params: {
      search: searchDoctor,
      patient_id: getValues('patient'),
      for: 'Patient',
    }
  }), {
    refetchOnWindowFocus: true,
    enabled: !!getValues('patient')
  });

  const { data: patientData, loadingPatient } = useQuery(['get-patient', searchPatient], () => api.getSelectPatient({
    params: {
      search: searchPatient,
      for: 'Appointment',
    }
  }), {
    refetchOnWindowFocus: false
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => {
    return data ? api.updateAppointment(params) : api.addAppointment(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit site', 'error')
    }
  })

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name == 'patient') fetchDoctor();
    });
    return () => subscription.unsubscribe();
  }, [watch])

  useEffect(() => {
    if (data) {
      setValue('id', data.id)
    } else {
      setValue('id', null)
    }
    reset({
      ...data,
      patient: data?.client_id,
      doctor: data?.doctor_id
    } ?? {});
  }, [data])

  useEffect(() => {
    if (defaultPatient) {
      setValue('patient', defaultPatient.value)
    }
  }, [defaultPatient])

  const onSubmit = () => mutate(getValues());

  return (
    <Modal
      size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Appointment' : 'Create Appointment'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
          }}>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-email-Input">Patient</Label>
                <Select
                  options={patientData}
                  onInputChange={(e) => setSearchPatient(e)}
                  onChange={(e) => setValue('patient', e.value)}
                  isLoading={loadingPatient}
                  defaultValue={data ? { label: data.client.fullname, value: data.client.id } : defaultPatient ? defaultPatient : null}
                />
                {errors.patient && <span className="form-text text-danger">{errors.patient[0]}</span>}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-password-Input">Doctor</Label>
                <Select
                  options={doctorData}
                  onInputChange={(e) => setSearchDoctor(e)}
                  onChange={(e) => setValue('doctor', e.value)}
                  isLoading={loadingDoctor}
                  classNamePrefix="select2-selection"
                  defaultValue={data && { label: data.doctor.fullname, value: data.doctor.id }}
                />
                {errors.doctor && <span className="form-text text-danger">{errors.doctor[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputDate">Appointment Date</Label>
                <Input
                  type="datetime-local"
                  className="form-control"
                  id="formrow-InputDate"
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                  placeholder="Appointment Date"
                  max="9999-12-31T23:59"
                  defaultValue={formatDateTimeInput(data?.date)}
                  onInput={(e) => setValue('date', e.target.value)}
                />
                {errors.date && <span className="form-text text-danger">{errors.date[0]}</span>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-Meeting">Meeting Preferences</Label>
                <select
                  id="formrow-Meeting"
                  className="form-control"
                  defaultValue={data?.meeting_preferences}
                  onChange={(e) => setValue('meeting_preferences', e.target.value)}
                >
                  <option value="">Choose...</option>
                  <option value="Zoom">Zoom</option>
                  <option value="Skype">Skype</option>
                  <option value="Telehealth">Telehealth</option>
                  <option value="F2F">F2F</option>
                </select>
                {errors.meeting && <span className="form-text text-danger">{errors.meeting[0]}</span>}
              </div>
            </Col>
          </Row>

          <div className="mb-3">
            <Label htmlFor="formrow-Message">Message</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Message"
              id="formrow-Message"
              value={data?.message}
              onInput={(e) => setValue('message', e.target.value)}
            />
            {errors.message && <span className="form-text text-danger">{errors.message[0]}</span>}
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary w-md" disabled={submitLoading} onClick={() => onSubmit()}>
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateAppointment.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
  defaultPatient: PropTypes.any,
};

export default CreateUpdateAppointment;