import { showToast } from 'helpers/util';
import PropTypes from 'prop-types';
import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Col, Input, Label, Row } from 'reactstrap';

const ConfirmationInput = ({ showConfirm, setShowConfirm, action, accept, title = 'Are you sure?', actionReject }) => {
  const [success, setSuccess] = useState(false)
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')

  return (
    <>
      {success && (
        <SweetAlert
          success
          timeout={1500}
          title="Processed"
          onConfirm={() => {
            setSuccess(false)
          }}
        >
          Your request has been processed.
        </SweetAlert>
      )}

      {showConfirm && (
        <SweetAlert
          title="Do you want to break?"
          showCancel
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            if (!start || !end) {
              return showToast('Please fill the required field', 'error')
            }
            action(start, end)
            setShowConfirm(false)
            setSuccess(true)
          }}
          onCancel={() => { setShowConfirm(false) }}
          type={'controlled'}
          dependencies={[start, end]}
        >
          <Row>
            <Col md={6}>
              <Label>Start</Label>
              <Input className="form-control" type="time" onChange={(e) => setStart(e.target.value)} required />
            </Col>
            <Col md={6}>
              <Label>End</Label>
              <Input className="form-control" type="time" onChange={(e) => setEnd(e.target.value)} required />
            </Col>
          </Row>
        </SweetAlert>
      )}
    </>
  );
}

ConfirmationInput.propTypes = {
  showConfirm: PropTypes.bool,
  setShowConfirm: PropTypes.func,
  action: PropTypes.func,
  accept: PropTypes.bool,
  title: PropTypes.string,
  actionReject: PropTypes.func,
};

export default ConfirmationInput