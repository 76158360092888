import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { get, post, put } from 'helpers/api_helper';
import { debounce } from 'lodash';
import { formatDateTimeInput, showToast } from 'helpers/util';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';

const CreateUpdateSite = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { getValues, setValue, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data.id : null,
    }
  });
  const { mutate } = useMutation((params) => {
    return data ? api.updateSite(params) : api.addSite(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      setLoading(false)
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit site', 'error')
    }
  })

  useEffect(() => {
    if (data) {
      setValue('id', data.id)
    }else{
      setValue('id', null)
    }
    reset(data ?? {});
  }, [data])

  const onSubmit = () => mutate(getValues());

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Site' : 'Create Site'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(onSubmit())
          }}>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-practice-name">Practice Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-practice-name"
                  placeholder="Practice Name"
                  defaultValue={data?.practice_name}
                  onChange={(e) => setValue('practice_name', e.target.value)}
                />
                {errors?.practice_name && <span className="form-text text-danger">{errors?.practice_name[0]}</span>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-practice-alias">Practice Alias</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-practice-alias"
                  placeholder="Practice Alias"
                  defaultValue={data?.practice_alias}
                  onChange={(e) => setValue('practice_alias', e.target.value)}
                />
                {errors?.practice_alias && <span className="form-text text-danger">{errors?.practice_alias[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-price">ABN</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-abn"
                  placeholder="ABN"
                  defaultValue={data?.abn}
                  onChange={(e) => setValue('abn', e.target.value)}
                />
                {errors?.abn && <span className="form-text text-danger">{errors?.abn[0]}</span>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-acn">ACN</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-acn"
                  placeholder="ACN"
                  defaultValue={data?.acn}
                  onChange={(e) => setValue('acn', e.target.value)}
                />
                {errors?.acn && <span className="form-text text-danger">{errors?.acn[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-office-type">Office Type</Label>
                <select
                  id="formrow-office-type"
                  className="form-control"
                  defaultValue={data?.office_type}
                  onChange={(e) => setValue('office_type', e.target.value)}
                >
                  <option value="">Choose...</option>
                  <option value="Physical Office">Physical Office</option>
                  <option value="Virtual Office">Virtual Office</option>
                </select>
                {errors?.office_type && <span className="form-text text-danger">{errors?.office_type[0]}</span>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-office-number">Telephone Number</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-office-number"
                  placeholder="Telephone Number"
                  defaultValue={data?.office_number}
                  onChange={(e) => setValue('office_number', e.target.value)}
                />
                {errors?.office_number && <span className="form-text text-danger">{errors?.office_number[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">Address</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-address"
                  placeholder="Address"
                  defaultValue={data?.address}
                  onChange={(e) => setValue('address', e.target.value)}
                />
                {errors?.address && <span className="form-text text-danger">{errors?.address[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-city">Suburb</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-city"
                  placeholder="Suburb"
                  defaultValue={data?.city}
                  onChange={(e) => setValue('city', e.target.value)}
                />
                {errors?.city && <span className="form-text text-danger">{errors?.city[0]}</span>}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-state">State</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-state"
                  placeholder="State"
                  defaultValue={data?.state}
                  onChange={(e) => setValue('state', e.target.value)}
                />
                {errors?.state && <span className="form-text text-danger">{errors?.state[0]}</span>}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-postcode">Postcode</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-postcode"
                  placeholder="Postcode"
                  defaultValue={data?.postcode}
                  onChange={(e) => setValue('postcode', e.target.value)}
                />
                {errors?.postcode && <span className="form-text text-danger">{errors?.postcode[0]}</span>}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary w-md" disabled={loading} onClick={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit())
          }}>
            {loading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateSite.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default React.memo(CreateUpdateSite);