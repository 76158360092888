import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { getRole, showToast } from "helpers/util"
import Confirmation from "components/Alert/Confirmation"
import { useMutation } from "@tanstack/react-query"
import api from "helpers/api"
import ConfirmationInput from "components/Alert/ConfirmationInput"
import { breakSuccess, checkInSuccess } from "store/actions"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")
  const [showConfirm, setShowConfirm] = useState(false)
  const [showCheckIn, setShowCheckIn] = useState(false)
  const [showBreak, setShowBreak] = useState(false)

  const { mutate: submitBreak } = useMutation((data) => api.addNewTimesheet(data), {
    onSuccess: (res) => {
      showToast('Successfully Set Break', 'success')
      dispatch(breakSuccess(res))
    },
  });

  const { mutate: submitCheckIn, isLoading: loadingCheckIn } = useMutation(() => {
    return api.checkin({
      user_id: JSON.parse(localStorage.getItem('authUser')).user.id,
      class: 'bg-success external-event text-white p-1 present',
    })
  }, {
    onSuccess: (res) => {
      showToast('Check In Successfully', 'success')
      dispatch(checkInSuccess(res))
    },
    onError: (err) => {
      showToast('Failed to check in', 'error')
    }
  })

  const { mutate, isLoading: submitLoading } = useMutation(() => {
    return api.checkout({
      user_id: JSON.parse(localStorage.getItem('authUser')).user.id
    })
  }, {
    onSuccess: (res) => {
      localStorage.removeItem('authUser');
      window.location.replace(window.location.origin + '/login');
    },
    onError: (err) => {
      showToast('Failed to logout', 'error')
    }
  })

  const handleBreak = (start, end) => {
    submitBreak({
      user_id: JSON.parse(localStorage.getItem('authUser')).user.id,
      title: 'Break',
      start,
      end,
      class: 'bg-warning external-event text-white p-1 break',
    });
    // window.location.replace(window.location.origin + '/login');
  }

  const logout = () => {
    redirectLogout();
  }

  const redirectLogout = () => {
    localStorage.removeItem('authUser');
    window.location.replace(window.location.origin + '/login');
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj?.user?.fullname)
      }
    }
  }, [props.success])

  // useEffect(() => {
  //   if (!props.isCheckIn || !props.isBreak) {
  //     get('check-action').then(res => {
  //       dispatch(checkInSuccess(res.checkin))
  //       dispatch(breakSuccess(res.break))
  //     });
  //   }
  // }, [])


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src="https://img2.pngdownload.id/20180402/ojw/kisspng-united-states-avatar-organization-information-user-avatar-5ac20804a62b58.8673620215226654766806.jpg"
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {getRole() == 'Patient' ? (
            <DropdownItem tag="a" href={`/patients/${JSON.parse(localStorage.getItem('authUser')).user.id}`}>
              {" "}
              <i className="bx bx-user font-size-16 align-middle me-1"/>
              {props.t("Profile")}{" "}
            </DropdownItem>
          ) : ''}
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1"/>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}
          {getRole() != 'Patient' ? (
            <>
              {!props.isCheckIn ? (
                <a className="dropdown-item" onClick={() => setShowCheckIn(true)}>
                  <i className="bx bx-calendar-check font-size-16 align-middle me-1 text-success"/>
                  <span>Check In</span>
                </a>
              ) : null}
              {!props.isBreak && props.isCheckIn ? (
                <a className="dropdown-item" onClick={() => setShowBreak(true)}>
                  <i className="bx bx-stopwatch font-size-16 align-middle me-1 text-warning"/>
                  <span>Set Break</span>
                </a>
              ) : null}
              {props.isCheckIn && props.isBreak ? null : (
                <div className="dropdown-divider"/>
              )}
              <a className="dropdown-item" onClick={() => setShowConfirm(true)}>
                <i className="bx bx-calendar-x font-size-16 align-middle me-1 text-danger"/>
                <span>{props.t("Check Out")}</span>
              </a>
            </>
          ) : null}
          <a className="dropdown-item" onClick={() => logout()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>{props.t("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
      <Confirmation showConfirm={showConfirm} setShowConfirm={() => setShowConfirm(false)} action={mutate} title="Do you want to check out?" actionReject={null} />
      <Confirmation showConfirm={showCheckIn} setShowConfirm={() => setShowCheckIn(false)} action={submitCheckIn} title="Do you want to check in?" actionReject={null} />
      <ConfirmationInput showConfirm={showBreak} setShowConfirm={() => setShowBreak(false)} action={handleBreak} actionReject={redirectLogout} />
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  isCheckIn: PropTypes.any,
  isBreak: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success, isCheckIn, isBreak } = state.Profile
  return { error, success, isCheckIn, isBreak }
}

const mapDispatchToProps = {
}

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(withTranslation()(ProfileMenu))
)
