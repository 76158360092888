import React, { useState } from "react"
import { Card, CardBody, CardTitle, Button, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import PropTypes from 'prop-types'
import { formatDate, getRole, getUrl, showToast } from "helpers/util"
import { get } from "helpers/api_helper"
import DocumentModal from "./DocumentModal"
import { useMutation } from "@tanstack/react-query"
import api from "helpers/api"
import DeleteConfirmation from "components/Alert/DeleteConfirmation"

const type = 'medicare_file';

const ScriptReminder = ({ data, refetch }) => {
  const [selectedKey, setSelectedKey] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false)

  const { mutate: deleteDocument, isLoading: deleteLoading } = useMutation((params) => api.removeDocument(params), {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      refetch()
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit', 'error')
    }
  });

  const toggleModal = () => {
    if (data.user.requirement?.medicare_file?.length == 5) {
      showToast('Maximum upload is 5 file', 'error')
    }
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  const onDelete = () => {
    deleteDocument({
      user_id: data.user.id,
      type,
      key: selectedKey
    })
  };

  const sendReminder = async (id) => {
    const res = await get(`/send-referral/${id}`);
    showToast(res.message)
  }

  return (
    <React.Fragment>
      <Card style={{ height: 400 }}>
        <CardBody>
          <div className="d-md-flex justify-content-between">
            <CardTitle className="mb-4">Prescription</CardTitle>
            <Button size="sm" color="primary" style={{ height: 30 }} onClick={toggleModal}>Upload New</Button>
          </div>
          <Table bordered>
            <thead className="table-light">
              <tr>
                <th>Filename</th>
                <th  className="text-center">Exp</th>
                <th  className="text-center">Status</th>
                <th  className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.user.requirement?.medicare_file?.length > 0 ? data.user.requirement?.medicare_file?.map((item, key) => (
                item ? (
                  <tr key={key}>
                    <td>
                      <a href={item ? getUrl(item?.url) : '#'} target="_blank" rel="noreferrer">
                        {item?.url?.split('/')[5]?.substring(14)}
                      </a>
                    </td>
                    <td>{item?.date}</td>
                    <td>{new Date(item?.date).getTime() < new Date().getTime() && item?.date ? 'Expired' : 'Active'}</td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle caret size="sm">
                          Action
                        </DropdownToggle>
                        <DropdownMenu>
                          {getRole() != 'Patient' ? new Date(item?.date).getTime() < new Date().getTime() && item?.date ? null : (
                            <DropdownItem onClick={() => sendReminder(data.user.requirement?.id)}>Send Reminder {data.user.requirement?.reminder_counter > 1 && 'Again'}</DropdownItem>
                          ) : null}
                          <DropdownItem>
                            <a href={item ? getUrl(item?.url) : '#'} target="_blank" rel="noreferrer">
                              View
                            </a>
                          </DropdownItem>
                          <DropdownItem onClick={() => {
                            setShowDelete(true)
                            setSelectedKey(key)
                          }}>Delete</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ) : null
              )) : null}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <DocumentModal modal={showModal} toggle={() => setShowModal(false)} handleCreateModal={toggleModal} data={data} type={type} refetch={refetch} />
      <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={onDelete} />

    </React.Fragment>
  )
}

ScriptReminder.propTypes = {
  data: PropTypes.array,
  refetch: PropTypes.func,
};

export default React.memo(ScriptReminder)
