import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { post, put } from "helpers/api_helper"
import { getRole, showToast } from "helpers/util"
import classnames from "classnames"
import Select from "react-select"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "helpers/api"

const CreateUpdateStaff = ({
  data,
  toggle,
  modal,
  handleCreateModal,
  refresh,
}) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [errors, setErrors] = useState([])
  const { data: roles } = useQuery(["roles"], () => api.getRoles(), {
    refetchOnWindowFocus: false,
  })
  const { data: sites } = useQuery(["sites"], () => api.getSelectSite(), {
    refetchOnWindowFocus: false,
  })
  const {
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { formErrors },
  } = useForm({
    defaultValues: {
      id: data ? data.id : null,
    },
  })
  const { mutate, isLoading: submitLoading } = useMutation(
    params => {
      return data ? api.updateStaff(params) : api.addStaff(params)
    },
    {
      onSuccess: res => {
        showToast(res.message, !res.status && "error")
        if (!res.status) {
          setErrors(res.errors)
          return
        }
        toggle()
        refresh()
      },
      onError: err => {
        showToast("Failed to submit site", "error")
      },
    }
  )

  const onSubmit = () => mutate(getValues())

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  useEffect(() => {
    setErrors([])
    if (data) {
      setValue("id", data.id)
    } else {
      setValue("id", null)
    }
    reset(
      {
        ...data,
        roles: data?.role_id,
        sites: data?.sites.map(val => val.id),
        emergency_name: data?.emergency_contact?.first_name,
        emergency_last_name: data?.emergency_contact?.last_name,
        emergency_email: data?.emergency_contact?.email,
        emergency_phone: data?.emergency_contact?.phone,
        emergency_relationship: data?.emergency_contact?.relationship,
      } ?? {}
    )
  }, [data])

  return (
    <Modal size="lg" toggle={() => handleCreateModal()} isOpen={modal} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Staff" : "Create Staff"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(onSubmit())
          }}
        >
          <Row>
            <Col md="3">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Emergency Contact
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Password
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Sites
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Roles
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="9">
              <TabContent
                activeTab={verticalActiveTab}
                className="text-muted mt-4 mt-md-0"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-first-name-Input">
                          First Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-first-name-Input"
                          placeholder="First Name"
                          defaultValue={data?.name}
                          onChange={e => setValue("name", e.target.value)}
                        />
                        {errors.name && (
                          <span className="form-text text-danger">
                            {errors.name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-last-name-Input">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-last-name-Input"
                          placeholder="Last Name"
                          defaultValue={data?.last_name}
                          onChange={e => setValue("last_name", e.target.value)}
                        />
                        {errors.lastName && (
                          <span className="form-text text-danger">
                            {errors.lastName[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">Email</Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="formrow-email-Input"
                          placeholder="Email"
                          defaultValue={data?.email}
                          onChange={e => setValue("email", e.target.value)}
                        />
                        {errors.email && (
                          <span className="form-text text-danger">
                            {errors.email[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-phone-Input">Phone</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-phone-Input"
                          placeholder="Phone"
                          defaultValue={data?.phone}
                          onChange={e => setValue("phone", e.target.value)}
                        />
                        {errors.phone && (
                          <span className="form-text text-danger">
                            {errors.phone[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-dob-Input">Date of Birth</Label>
                        <Input
                          type="date"
                          className="form-control"
                          id="formrow-dob-Input"
                          placeholder="Date of Birth"
                          defaultValue={data?.b_date}
                          onChange={e => setValue("b_date", e.target.value)}
                        />
                        {errors.b_date && (
                          <span className="form-text text-danger">
                            {errors.b_date[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-status-Input">Status</Label>
                        <select
                          id="formrow-status-Input"
                          className="form-control"
                          defaultValue={data?.status}
                          onInput={e => setValue("status", e.target.value)}
                        >
                          <option value="">Choose...</option>
                          <option value="ACTIVE">ACTIVE</option>
                          <option value="INACTIVE">INACTIVE</option>
                        </select>
                        {errors.status && (
                          <span className="form-text text-danger">
                            {errors.status[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div className="mb-3">
                    <Label htmlFor="formrow-Address">Address</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      id="formrow-Address"
                      defaultValue={data?.address}
                      onChange={e => setValue("address", e.target.value)}
                    />
                    {errors.address && (
                      <span className="form-text text-danger">
                        {errors.address[0]}
                      </span>
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-first-name-Input">
                          First Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-first-name-Input"
                          placeholder="First Name"
                          defaultValue={data?.emergency_contact?.first_name}
                          onInput={e =>
                            setValue("emergency_name", e.target.value)
                          }
                        />
                        {errors.emergency_name && (
                          <span className="form-text text-danger">
                            {errors.emergency_name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-last-name-Input">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-last-name-Input"
                          placeholder="Last Name"
                          defaultValue={data?.emergency_contact?.last_name}
                          onInput={e =>
                            setValue("emergency_last_name", e.target.value)
                          }
                        />
                        {errors.emergency_last_name && (
                          <span className="form-text text-danger">
                            {errors.emergency_last_name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-emergency-phone-Input">
                          Phone
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-emergency-phone-Input"
                          placeholder="Phone"
                          defaultValue={data?.emergency_contact?.phone}
                          onInput={e =>
                            setValue("emergency_phone", e.target.value)
                          }
                        />
                        {errors.emergency_phone && (
                          <span className="form-text text-danger">
                            {errors.emergency_phone[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-emergency-email-Input">
                          Email
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-emergency-email-Input"
                          placeholder="Email"
                          defaultValue={data?.emergency_contact?.email}
                          onInput={e =>
                            setValue("emergency_email", e.target.value)
                          }
                        />
                        {errors.emergency_email && (
                          <span className="form-text text-danger">
                            {errors.emergency_email[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-Relationship-Input">
                          Relationship
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-Relationship-Input"
                          placeholder="Relationship"
                          defaultValue={data?.emergency_contact?.relationship}
                          onInput={e =>
                            setValue("emergency_relationship", e.target.value)
                          }
                        />
                        {errors.emergency_relationship && (
                          <span className="form-text text-danger">
                            {errors.emergency_relationship[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password-Input">Password</Label>
                        <Input
                          type="password"
                          className="form-control"
                          id="formrow-password-Input"
                          placeholder="Password"
                          onChange={e => setValue("password", e.target.value)}
                        />
                        {errors.password && (
                          <span className="form-text text-danger">
                            {errors.password[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password_confirmation-Input">
                          Password Confirmation
                        </Label>
                        <Input
                          type="password"
                          className="form-control"
                          id="formrow-password_confirmation-Input"
                          placeholder="Password Confirmation"
                          onChange={e =>
                            setValue("password_confirmation", e.target.value)
                          }
                        />
                        {errors.password_confirmation && (
                          <span className="form-text text-danger">
                            {errors.password_confirmation[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-dob-Input">Sites</Label>
                        <Select
                          options={sites?.sites}
                          isMulti
                          onChange={e =>
                            setValue(
                              "sites",
                              e.map(val => val.value)
                            )
                          }
                          defaultValue={
                            data &&
                            sites?.sites.filter(site =>
                              data.sites.find(
                                userSite => site.value == userSite.id
                              )
                            )
                          }
                        />
                        {errors.sites && (
                          <span className="form-text text-danger">
                            {errors.sites[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="5">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-role-Input">Roles</Label>
                        <Select
                          options={roles?.roles}
                          onChange={e => setValue("roles", e.value)}
                          defaultValue={roles?.roles?.find(
                            item => item.value == data?.role_id
                          )}
                        />
                        {errors.roles && (
                          <span className="form-text text-danger">
                            {errors.roles[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-permissions-Input">
                          Special Permissions
                        </Label>
                        <Select
                          options={roles?.roles}
                          isMulti
                          onChange={e => setValue("permissions", e.value)}
                          // defaultValue={
                          //   data && {
                          //     label: data.client.fullname,
                          //     value: data.client.id,
                          //   }
                          // }
                        />
                        {errors.roles && (
                          <span className="form-text text-danger">
                            {errors.roles[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={() => handleSubmit(onSubmit())}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateStaff.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
}

export default React.memo(CreateUpdateStaff)
