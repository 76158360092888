import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { post, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';
import Select from "react-select";
import api from 'helpers/api';
import classnames from 'classnames';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

const CreateUpdateDoctor = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [sites, setSites] = useState([])
  const [roles, setRoles] = useState([])
  const [errors, setErrors] = useState([])
  const results = useQueries({
    queries: [
      {
        queryKey: ['doctor-create-update', 1], queryFn: () => api.getRoles(), onSuccess: (res) => {
          setRoles(res)
        }
      },
      {
        queryKey: ['doctor-create-update', 2], queryFn: () => api.getSelectSite(), onSuccess: (res) => {
          setSites(res)
        }
      },
    ]
  })

  const { getValues, setValue, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data.id : null,
    }
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => {
    return data ? api.updateDoctor(params) : api.addDoctor(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit doctor', 'error')
    }
  })

  const onSubmit = () => mutate(getValues());

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  useEffect(() => {
    if (data) {
      setValue('id', data.id)
    } else {
      setValue('id', null)
    }
    reset({
      ...data,
      ...data?.doctor,
      specialist: data?.specialist.specialist_name,
      sites: data?.user.sites.map(val => val.id),
      emergency_name: data?.user.emergency_contact?.first_name,
      emergency_last_name: data?.user.emergency_contact?.last_name,
      emergency_email: data?.user.emergency_contact?.email,
      emergency_phone: data?.user.emergency_contact?.phone,
      emergency_relationship: data?.user.emergency_contact?.relationship,
    } ?? {});
  }, [data])

  return (
    <Modal
      size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Doctor' : 'Create Doctor'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(onSubmit())
          }}>
          <Row>
            <Col md="3">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1");
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2");
                    }}
                  >
                    Emergency Contact
                  </NavLink>
                </NavItem>
                {data && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleVertical("3");
                      }}
                    >
                      Password
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4");
                    }}
                  >
                    Sites
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5");
                    }}
                  >
                    Roles
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6");
                    }}
                  >
                    Billing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleVertical("7");
                    }}
                  >
                    Meetings
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="9">
              <TabContent
                activeTab={verticalActiveTab}
                className="text-muted mt-4 mt-md-0"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-first-name-Input">First Name</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-first-name-Input"
                          placeholder="First Name"
                          defaultValue={data?.doctor.name}
                          onInput={(e) => setValue('name', e.target.value)}
                        />
                        {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-last-name-Input">Last Name</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-last-name-Input"
                          placeholder="Last Name"
                          defaultValue={data?.doctor.last_name}
                          onInput={(e) => setValue('last_name', e.target.value)}
                        />
                        {errors.last_name && <span className="form-text text-danger">{errors.last_name[0]}</span>}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">Email</Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="formrow-email-Input"
                          placeholder="Email"
                          defaultValue={data?.doctor.email}
                          onInput={(e) => setValue('email', e.target.value)}
                        />
                        {errors.email && <span className="form-text text-danger">{errors.email[0]}</span>}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-phone-Input">Personal Email</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-personal-email-Input"
                          placeholder="Personal Email"
                          defaultValue={data?.personal_email}
                          onInput={(e) => setValue('personal_email', e.target.value)}
                        />
                        {errors.personal_email && <span className="form-text text-danger">{errors.personal_email[0]}</span>}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-phone-Input">Phone</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-phone-Input"
                          placeholder="Phone"
                          defaultValue={data?.doctor.phone}
                          onInput={(e) => setValue('phone', e.target.value)}
                        />
                        {errors.phone && <span className="form-text text-danger">{errors.phone[0]}</span>}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-peronal-phone-Input">Personal Phone</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-peronal-phone-Input"
                          placeholder="Personal Phone"
                          defaultValue={data?.personal_phone}
                          onInput={(e) => setValue('personal_phone', e.target.value)}
                        />
                        {errors.personal_phone && <span className="form-text text-danger">{errors.personal_phone[0]}</span>}
                      </div>
                    </Col>
                  </Row>

                  {!data && (
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Password</Label>
                          <Input
                            type="password"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder="Password"
                            onInput={(e) => setValue('password', e.target.value)}
                          />
                          {errors.password && <span className="form-text text-danger">{errors.password[0]}</span>}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password_confirmation-Input">Password Confirmation</Label>
                          <Input
                            type="password"
                            className="form-control"
                            id="formrow-password_confirmation-Input"
                            placeholder="Password Confirmation"
                            onInput={(e) => setValue('password_confirmation', e.target.value)}
                          />
                          {errors.password_confirmation && <span className="form-text text-danger">{errors.password_confirmation[0]}</span>}
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-dob-Input">Date of Birth</Label>
                        <Input
                          type="date"
                          className="form-control"
                          id="formrow-dob-Input"
                          placeholder="Date of Birth"
                          defaultValue={data?.doctor.b_date}
                          onInput={(e) => setValue('b_date', e.target.value)}
                        />
                        {errors.b_date && <span className="form-text text-danger">{errors.b_date[0]}</span>}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-Gender">Gender</Label>
                        <select
                          id="formrow-Gender"
                          className="form-control"
                          defaultValue={data?.gender}
                          onInput={(e) => setValue('gender', e.target.value)}
                        >
                          <option value="">Choose...</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        {errors.gender && <span className="form-text text-danger">{errors.gender[0]}</span>}
                      </div>
                    </Col>
                  </Row>

                  <div className="mb-3">
                    <Label htmlFor="formrow-Address">Address</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      id="formrow-Address"
                      defaultValue={data?.address}
                      onInput={(e) => setValue('address', e.target.value)}
                    />
                    {errors.address && <span className="form-text text-danger">{errors.address[0]}</span>}
                  </div>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-Gender">Specialist</Label>
                        <select
                          id="formrow-Gender"
                          className="form-control"
                          defaultValue={data?.specialist.specialist_name}
                          onInput={(e) => setValue('specialist', e.target.value)}
                        >
                          <option value="">Choose...</option>
                          <option value="Allergy and immunology">Allergy and immunology</option>
                          <option value="Anesthesiology">Anesthesiology</option>
                          <option value="Cardiology">Cardiology</option>
                          <option value="Dermatology">Dermatology</option>
                          <option value="Diagnostic Radiology">Diagnostic Radiology</option>
                          <option value="Emergency medicine">Emergency medicine</option>
                          <option value="Neurology">Neurology</option>
                          <option value="Obstetrics and gynecology">Obstetrics and gynecology
                          </option>
                          <option value="Ophthalmology">Ophthalmology</option>
                          <option value="Pathology">Pathology</option>
                          <option value="Pediatrics">Pediatrics</option>
                          <option value="Psychiatry">Psychiatry</option>
                          <option value="Surgery">Surgery</option>
                          <option value="Urology">Urology</option>
                        </select>
                        {errors.specialist && <span className="form-text text-danger">{errors.specialist[0]}</span>}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-ProviderNumber-Input">Provider Number</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-ProviderNumber-Input"
                          placeholder="Provider Number"
                          defaultValue={data?.provider_number}
                          onInput={(e) => setValue('provider_number', e.target.value)}
                        />
                        {errors.provider_number && <span className="form-text text-danger">{errors.provider_number[0]}</span>}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-national-id-Input">National ID</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-national-id-Input"
                          placeholder="National ID"
                          defaultValue={data?.national_id}
                          onInput={(e) => setValue('national_id', e.target.value)}
                        />
                        {errors.national_id && <span className="form-text text-danger">{errors.national_id[0]}</span>}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-ahpra-number-Input">Ahpra Number</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-ahpra-number-Input"
                          placeholder="Ahpra Number"
                          defaultValue={data?.ahpra_number}
                          onInput={(e) => setValue('ahpra_number', e.target.value)}
                        />
                        {errors.ahpra_number && <span className="form-text text-danger">{errors.ahpra_number[0]}</span>}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-first-name-Input">First Name</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-first-name-Input"
                          placeholder="First Name"
                          defaultValue={data?.user.emergency_contact?.first_name}
                          onInput={(e) => setValue('emergency_name', e.target.value)}
                        />
                        {errors.emergency_name && <span className="form-text text-danger">{errors.emergency_name[0]}</span>}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-last-name-Input">Last Name</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-last-name-Input"
                          placeholder="Last Name"
                          defaultValue={data?.user.emergency_contact?.last_name}
                          onInput={(e) => setValue('emergency_last_name', e.target.value)}
                        />
                        {errors.emergency_last_name && <span className="form-text text-danger">{errors.emergency_last_name[0]}</span>}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-emergency-phone-Input">Phone</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-emergency-phone-Input"
                          placeholder="Phone"
                          defaultValue={data?.user.emergency_contact?.phone}
                          onInput={(e) => setValue('emergency_phone', e.target.value)}
                        />
                        {errors.emergency_phone && <span className="form-text text-danger">{errors.emergency_phone[0]}</span>}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-emergency-email-Input">Email</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-emergency-email-Input"
                          placeholder="Email"
                          defaultValue={data?.user.emergency_contact?.email}
                          onInput={(e) => setValue('emergency_email', e.target.value)}
                        />
                        {errors.emergency_email && <span className="form-text text-danger">{errors.emergency_email[0]}</span>}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-Relationship-Input">Relationship</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-Relationship-Input"
                          placeholder="Relationship"
                          defaultValue={data?.user.emergency_contact?.relationship}
                          onInput={(e) => setValue('emergency_relationship', e.target.value)}
                        />
                        {errors.emergency_relationship && <span className="form-text text-danger">{errors.emergency_relationship[0]}</span>}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password-Input">Password</Label>
                        <Input
                          type="password"
                          className="form-control"
                          id="formrow-password-Input"
                          placeholder="Password"
                          onChange={(e) => setValue('password', e.target.value)}
                        />
                        {errors.password && <span className="form-text text-danger">{errors.password[0]}</span>}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password_confirmation-Input">Password Confirmation</Label>
                        <Input
                          type="password"
                          className="form-control"
                          id="formrow-password_confirmation-Input"
                          placeholder="Password Confirmation"
                          onChange={(e) => setValue('password_confirmation', e.target.value)}
                        />
                        {errors.password_confirmation && <span className="form-text text-danger">{errors.password_confirmation[0]}</span>}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-dob-Input">Sites</Label>
                        <Select
                          options={sites?.sites}
                          isMulti
                          onChange={(e) => setValue('sites', e.map(val => val.value))}
                          defaultValue={data && sites?.sites.filter(
                            site => data.user.sites.find(userSite => site.value == userSite.id)
                          )}
                        />
                        {errors.sites && <span className="form-text text-danger">{errors.sites[0]}</span>}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="5">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-permissions-Input">Special Permissions</Label>
                        <Select
                          options={roles?.roles}
                          isMulti
                          onInput={(e) => setValue('permissions', e.value)}
                        // defaultValue={data && { label: data.client.fullname, value: data.client.id }}
                        />
                        {errors.roles && <span className="form-text text-danger">{errors.roles[0]}</span>}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="6">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-admin-fee-Input">Admin Fee (%)</Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="formrow-admin-fee-Input"
                          placeholder="Admin Fee"
                          defaultValue={data?.admin_fee}
                          onInput={(e) => setValue('admin_fee', e.target.value)}
                        />
                        {errors.admin_fee && <span className="form-text text-danger">{errors.admin_fee[0]}</span>}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-booking-cancellation-fee-Input">Booking Cancellation Fee (%)</Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="formrow-booking-cancellation-fee-Input"
                          placeholder="Booking Cancellation Fee"
                          defaultValue={data?.cancel_fee}
                          onInput={(e) => setValue('cancel_fee', e.target.value)}
                        />
                        {errors.cancel_fee && <span className="form-text text-danger">{errors.cancel_fee[0]}</span>}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="7">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-zoom-link-Input">Zoom Meeting Link</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-zoom-link-Input"
                          placeholder="Zoom Meeting Link"
                          defaultValue={data?.zoom_link}
                          onInput={(e) => setValue('zoom_link', e.target.value)}
                        />
                        {errors.zoom_link && <span className="form-text text-danger">{errors.zoom_link[0]}</span>}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-zoom-id-Input">Zoom ID</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-zoom-id-Input"
                          placeholder="Zoom ID"
                          defaultValue={data?.zoom_id}
                          onInput={(e) => setValue('zoom_id', e.target.value)}
                        />
                        {errors.zoom_id && <span className="form-text text-danger">{errors.zoom_id[0]}</span>}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-zoom-passcode-Input">Zoom Passcode</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-zoom-passcode-Input"
                          placeholder="Zoom ID"
                          defaultValue={data?.zoom_passcode}
                          onInput={(e) => setValue('zoom_passcode', e.target.value)}
                        />
                        {errors.zoom_passcode && <span className="form-text text-danger">{errors.zoom_passcode[0]}</span>}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>

        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary w-md" disabled={submitLoading} onClick={() => handleSubmit(onSubmit())}>
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateDoctor.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default React.memo(CreateUpdateDoctor);