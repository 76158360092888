const { get, post, put, del, postFormData } = require("./api_helper")

const api = {
  checkin(data) {
    return post('checkin', data);
  },
  checkout(data) {
    return post('checkout', data);
  },
  getSelectEmployee(data) {
    return get(`select-employee`, data);
  },
  getRoles(data) {
    return get(`get-roles`, data);
  },
  getSelectGp() {
    return get('select-gp');
  },
  getSelectSite() {
    return get('select-site');
  },
  getSite() {
    return get('sites');
  },
  addSite(data) {
    return post('sites', data);
  },
  updateSite(data) {
    return put(`sites/${data.id}`, data);
  },
  getStaff(data) {
    return get('staffs', data);
  },
  addStaff(data) {
    return post('staffs', data);
  },
  updateStaff(data) {
    return put(`staffs/${data.id}`, data);
  },
  getSelectDoctor(data) {
    return get('select-doctor', data);
  },
  getDoctor(data) {
    return get('doctors', data);
  },
  addDoctor(data) {
    return post('doctors', data);
  },
  updateDoctor(data) {
    return put(`doctors/${data.id}`, data);
  },
  getSelectPatient(data) {
    return get('select-patient', data);
  },
  getPatientCount(data) {
    return get('patient-count', data);
  },
  getPatient(data) {
    return get('patient', data);
  },
  getArchivePatient(data) {
    return get('archives-patient', data);
  },
  getExistPatient(data) {
    return get('exist-patient', data);
  },
  getNewPatient(data) {
    return get('new-patient', data);
  },
  getReferralPatient(data) {
    return get('referral-reminder', data);
  },
  getScriptPatient(data) {
    return get('script-reminder', data);
  },
  getPatientDetail(id) {
    return get(`patient/${id}`);
  },
  addPatient(data) {
    return postFormData('patient', data);
  },
  updatePatient(data, id) {
    return postFormData(`patient/${id}`, data);
  },
  flagPatient(data, id) {
    return post(`flag-patient/${id}`, data);
  },
  addDocument(data) {
    return postFormData(`add-document`, data);
  },
  removeDocument(data) {
    return post(`remove-document`, data);
  },
  verifyUser(data, id) {
    return post(`verify-user/${data.id}`, data);
  },
  updatePatientDetail(data) {
    return post(`patient-detail/${data?.user?.id}`, data);
  },
  getPatientType(data) {
    return get('patient-type', data);
  },
  getPatientProblem(data) {
    return get('patient-problem', data);
  },
  getEvents(data) {
    return get('events', data);
  },
  addNewEvent(data) {
    return post('events', data);
  },
  updateEvent(data) {
    return put(`events/${data.id}`, data);
  },
  deleteEvent(id) {
    return del(`events/${id}`);
  },
  getAvailability(data) {
    return get('availabilities', data);
  },
  addNewAvailability(data) {
    return post('availabilities', data);
  },
  updateAvailability(data) {
    return put(`availabilities/${data.id}`, data);
  },
  deleteAvailability(id) {
    return del(`availabilities/${id}`);
  },
  getTimesheet(data) {
    return get('timesheets', data);
  },
  getDetailTimesheet(data) {
    return get(`timesheets/${data.params.user_id}`, data);
  },
  addNewTimesheet(data) {
    return post('timesheets', data);
  },
  updateTimesheet(data) {
    return put(`timesheets/${data.id}`, data);
  },
  deleteTimesheet(id) {
    return del(`timesheets/${id}`);
  },
  addNewEventCategory(data) {
    return post('event-categories', data);
  },
  getSetting() {
    return get(`settings`);
  },
  updateSetting(data) {
    return postFormData(`update-settings`, data);
  },
  getTodayAppointment(data) {
    return get(`today-appointment`, data);
  },
  getFinishAppointment(data) {
    return get(`finish-appointment`, data);
  },
  getInvoices(data) {
    return get(`invoice`, data);
  },
  getAppointment(data) {
    return get(`appointment`, data);
  },
  addAppointment(data) {
    return post('appointment', data);
  },
  updateAppointment(data) {
    return put(`appointment/${data.id}`, data);
  },
  removePayment(data) {
    return get(`remove-payment/${data.id}`);
  },
  getBroadcasts(data) {
    return get(`broadcasts`, data);
  },
  sendBroadcasts(data) {
    return post(`broadcasts`, data);
  },
  getGeneralPractice(data) {
    return get('general-practices', data);
  },
  addGeneralPractice(data) {
    return post('general-practices', data);
  },
  updateGeneralPractice(data) {
    return put(`general-practices/${data.id}`, data);
  },
  getReportTimesheet(data) {
    return get('data-report-timesheet', data);
  },
  getReportIncome(data) {
    return get('data-report-income', data);
  },
  getReportIncomeChart(data) {
    return get('data-report-income-chart', data);
  },
  getReportDoctor(data) {
    return get('data-report-doctor', data);
  },
  getFailedBroadcast(data) {
    return get('failed-broadcasts', data);
  },
  resendFailedBroadcast(id) {
    return get(`failed-broadcasts/resend/${id}`);
  },
  resendEmailVerification(id) {
    return get(`patient/resend-verification/${id}`);
  },
}

export default api;