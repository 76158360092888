import React, { useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

//redux
import { useQuery } from "@tanstack/react-query";
import api from "helpers/api";

const DetailTimesheet = ({userId}) => {
  const [series, setSeries] = useState([10, 10, 10, 10, 10])
  const [options, setOptions] = useState({
    labels: ["Working Days", "Sick Leave", 'Day Off', 'Annual Leave'],
    colors: ["#34c38f", "#1D4FA0", "#f46a6a", "#74788d"],
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  })
  const [week, setWeek] = useState('')
  const { data: timesheet, refetch, isLoading: loading } = useQuery(['detail-timesheet', week, userId], () => api.getDetailTimesheet({
    params: {
      user_id: userId,
      week: week,
    }
  }), {
    onSuccess: (res) => {
      setSeries([res.data.total_working_days ?? 0, res.data.total_absent ?? 0, res.data.total_day_off ?? 0, res.data.total_annual_leave ?? 0])
    },
    enabled: !!userId,
    refetchOnWindowFocus: true
  });


  return (
    <React.Fragment>
      <Col xl="12">
        <Card>
          <CardBody>
            <div className="clearfix">
              <div className="float-end">
                <div className="input-group input-group-sm">
                  <input type="week" className="form-control" onChange={e => setWeek(e.target.valueAsDate)} />
                  {/* <select
                    className="form-select form-select-sm"
                    value={seletedMonth}
                    onChange={(e) => {
                      onChangeMonth(e.target.value);
                    }}
                  >
                    <option value="jan">Jan</option>
                    <option value="dec">Dec</option>
                    <option value="nov">Nov</option>
                    <option value="oct">Oct</option>
                  </select> */}
                  {/* <div className="input-group-append"> */}
                  <label className="input-group-text">Week</label>
                  {/* </div> */}
                </div>
              </div>
              <h4 className="card-title mb-4">Timesheet</h4>
            </div>

            {week ? (
              loading ? (
                <Spinner className="ms-2 spinner-loading" color="success" />
              ) : (
                <Row>
                  <Col lg="4">
                    <Row>
                      <Col>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Total Hours
                                </p>
                                <h4 className="mb-0">{timesheet?.data?.total_hours.toFixed(2)} Hours</h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "bx bx-time font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Personal Day
                                </p>
                                <h4 className="mb-0">{timesheet?.data?.total_break} Hours</h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "bx bx-time font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Total Sick Leave
                                </p>
                                <h4 className="mb-0">{timesheet?.data?.total_absent} Days</h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "bx bx-time font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg="8">
                    <div>
                      {timesheet?.data ? (
                        <div id="donut-chart">
                          <ReactApexChart
                            series={series}
                            options={options}
                            type="donut"
                            height={260}
                            className="apex-charts"
                          />
                        </div>
                      ) : null}
                    </div>

                    <div className="text-center text-muted">
                      <Row>
                        <Col xs="3">
                          <div className="mt-4">
                            <p className="mb-2 text-truncate">
                              <i className="mdi mdi-circle text-success" /> Working Days
                            </p>
                            <h5>{timesheet.data.total_working_days} Days</h5>
                          </div>
                        </Col>
                        <Col xs="3">
                          <div className="mt-4">
                            <p className="mb-2 text-truncate">
                              <i className="mdi mdi-circle text-primary" /> Sick Leave
                            </p>
                            <h5>{timesheet.data.total_absent} Days</h5>
                          </div>
                        </Col>
                        <Col xs="3">
                          <div className="mt-4">
                            <p className="mb-2 text-truncate">
                              <i className="mdi mdi-circle text-danger" /> Day Off
                            </p>
                            <h5>{timesheet.data.total_day_off} Days</h5>
                          </div>
                        </Col>
                        <Col xs="3">
                          <div className="mt-4">
                            <p className="mb-2 text-truncate">
                              <i className="mdi mdi-circle text-secondary" /> Annual Leave
                            </p>
                            <h5>{timesheet.data.total_annual_leave} Days</h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )
            ) : null}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

DetailTimesheet.propTypes = {
  userId: PropTypes.any,
};

export default React.memo(DetailTimesheet);
