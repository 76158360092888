import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import Select from "react-select";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { formatDateTimeInput } from 'helpers/util';

//import Images
// import verification from "../../assets/images/verification-img.png";

import {
  addNewEvent as onAddNewEvent,
  addNewEventCategory as onAddNewEventCategory,
  deleteEvent as onDeleteEvent,
  updateEvent as onUpdateEvent,
} from "../../store/actions";

import DeleteModal from "./components/DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import CreateUpdateSchedule from "./components/CreateUpdateSchedule";
import { useMutation, useQuery } from "@tanstack/react-query";
import api from "helpers/api";
import moment from "moment";
import CreateUpdateEvent from "./components/CreateUpdateEvent";

const Schedule = props => {
  const [event, setEvent] = useState({});
  const [site, setSite] = useState(null)
  const [userId, setUserId] = useState(null)
  const [schedules, setSchedules] = useState([])
  const [modal, setModal] = useState(false);
  const [modalCreate, setModalCreate] = useState(false)
  const [modalEvent, setModalEvent] = useState(false)
  const [modalDate, setModalDate] = useState(null)
  const [modalData, setModalData] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const { data: user, isLoading } = useQuery(['select-employee', site], () => api.getSelectEmployee({
    params: {
      site: site,
      for: 'Doctor',
    }
  }), {
    refetchOnWindowFocus: false,
  });
  const { data: schedule, refetch, isLoading: loading } = useQuery(['get-schedule', userId], () => api.getEvents({
    params: {
      user_id: userId,
      site: site,
    }
  }), {
    onSuccess: (res) => setSchedules(res),
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });

  const { data: sites, isLoading: sitesLoading } = useQuery(['select-sites'], () => api.getSelectSite(), {
    refetchOnWindowFocus: false
  });

  const { mutate } = useMutation((data) => data?.id ? api.updateEvent(data) : api.addNewEvent(data), {
    onSuccess: (res) => res.event && setSchedules(prev => [...prev, res?.event]),
  });

  const toggleEvent = () => {
    if (modalEvent) {
      setModalEvent(false);
    } else {
      setModalEvent(true);
    }
  };

  const toggleCreate = () => {
    if (modalCreate) {
      setModalCreate(false);
    } else {
      setModalCreate(true);
    }
  };

  const handleCreateModal = () => {
    toggleCreate();
    setModalData(null);
    setModalDate(null);
  };

  const handleEventModal = () => {
    toggleEvent();
    setModalData(null);
    setModalDate(null);
  };

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || '',
      category: (event && event.category) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        };
        // update event
        dispatch(onUpdateEvent(updateEvent));
        validation.resetForm();
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        };
        // save new event
        dispatch(onAddNewEvent(newEvent));
        validation.resetForm();
      }
      setSelectedDay(null);
      refetch();
      toggle();
    },
  });

  const categories = [{
    id: 1,
    type: 'bg-success',
    title: 'Appointment',
  },
  {
    id: 2,
    type: 'bg-primary',
    title: 'Meeting',
  },
  {
    id: 3,
    type: 'bg-warning',
    title: 'Lunch',
  },
  {
    id: 4,
    type: 'bg-danger',
    title: 'Off',
  }];

  useEffect(() => {
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, []);

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({});
        setIsEdit(false);
      }, 500);
    }
  }, [modal, event]);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
    } else {
      setModal(true);
    }
  };

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    const modifiedData = { ...arg, date: modifiedDate };

    setSelectedDay(modifiedData);
    toggle();
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = async (arg) => {
    const data = await arg.event.extendedProps;
    const date = arg.event.start;

    if (arg.event.classNames.includes('Appointment')) {
      setModalData({...data, id: arg.event.id, appointment: {
        ...data.appointment,
        date: formatDateTimeInput(date)
      }});
      toggleCreate();
    }else{
      setModalData({ ...data, id: arg.event.id, title: arg.event.title, start: arg.event.start });
      toggleEvent();
    }
  };

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    dispatch(onDeleteEvent(event));
    setDeleteModal(false);
    toggle();
  };

  /**
   * On category darg event
   */
  const onDrag = event => {
    event.preventDefault();
  };

  /**
   * On calendar drop event
   */
  const onDrop = (event, el) => {
    const date = event['date'];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);

    const draggedEl = event.draggedEl;
    const draggedElclass = draggedEl.className;

    if ((draggedEl.classList.contains('Meeting') || draggedEl.classList.contains('Lunch') || draggedEl.classList.contains('Off')) && draggedElclass.indexOf("fc-event-draggable") == -1) {
      setModalData({
        title: draggedEl.classList[draggedEl.classList.length - 1],
        start: formatDateTimeInput(modifiedDate),
        class: draggedEl.className,
      })
      setModalDate(modifiedDate);
      return toggleEvent();
    }
    if (draggedEl.classList.contains('Appointment') && draggedElclass.indexOf("fc-event-draggable") == -1) {
      if (!isEdit) {
        setModalDate(modifiedDate);
        return toggleCreate();
      }
    }

    if (draggedEl.classList.contains('external-event') && draggedElclass.indexOf("fc-event-draggable") == -1) {
      const modifiedData = {
        user_id: userId,
        title: draggedEl.innerText,
        start: modifiedDate,
        class: draggedEl.className,
      };
      mutate(modifiedData);
    }
  };

  const onReceive = async (arg) => {
    const date = arg.event.start;
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currentHour = date.getHours();
    const currentMin = date.getMinutes();
    const currentSec = date.getSeconds();
    const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);

    const draggedEl = arg.draggedEl;
    const draggedElclass = draggedEl.className;

    const data = await arg.event.extendedProps;
    if (draggedEl.classList.contains('Appointment')) {
      setModalData({ ...data, id: arg.event.id, appointment: {
        ...data.appointment,
        date: formatDateTimeInput(date)
      } });
      toggleCreate();
    }else{
      setModalData({
        id: arg.event.id,
        title: draggedEl.classList[draggedEl.classList.length - 1],
        start: formatDateTimeInput(modifiedDate),
        class: draggedEl.className,
      });
      toggleEvent();
    }
    const modifiedData = {
      id: arg.event.id,
      appointment_id: arg.event.extendedProps.appointment_id,
      user_id: userId,
      title: draggedEl.innerText,
      start: formatDateTimeInput(date),
      class: draggedEl.className,
    };
    mutate(modifiedData);
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {/* Render Breadcrumb */}
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col lg={3}>
                    <Select
                      options={sites?.sites}
                      onChange={(e) => setSite(e.value)}
                      isLoading={sitesLoading}
                      className="mb-2"
                      classNamePrefix="select2-selection"
                    />
                    <Select
                      options={user}
                      onChange={(e) => setUserId(e.value)}
                      isLoading={isLoading}
                      className="mb-2"
                      classNamePrefix="select2-selection"
                    />
                    <Button
                      color="primary"
                      className="font-16 btn-block col-md-12"
                      onClick={handleCreateModal}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Create New Appointment
                    </Button>

                    <div id="external-events" className="mt-3">
                      <p className="text-muted">
                        Drag and drop your activities or click in the calendar
                      </p>
                      {categories &&
                        categories.map((category, i) => (
                          <div
                            className={`${category.type} external-event text-white p-1 mb-2 ${category.title}`}
                            key={"cat-" + category.id}
                            draggable
                            onDrag={event => onDrag(event, category)}
                          >
                            <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                            {category.title}
                          </div>
                        ))}
                    </div>
                  </Col>
                  <Col className="col-lg-9">
                    {(loading || !schedules) ? (
                        <div className="text-center">Please Select User</div>
                    ) : (
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        slotDuration={"00:30:00"}
                        eventTimeFormat={{
                          hour: "2-digit",
                          minute: "2-digit",
                        }}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "timeGridDay",
                        }}
                        dayHeaderContent={(args) => {
                          if (args.view.type != "dayGridMonth") {
                            return moment(args.date).format('ddd DD/MM')
                          }
                        }}
                        events={schedules}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        eventOrder="date"
                        // dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        eventReceive={onReceive}
                        drop={onDrop}
                      />
                    )}

                    {/* New/Edit event modal */}
                    <Modal isOpen={modal} className={props.className}>
                      <ModalHeader toggle={toggle} tag="h4">
                        {!!isEdit ? "Edit Event" : "Add Event"}
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row form>
                            <Col className="col-12 mb-3">
                              <Label className="form-label">Event Name</Label>
                              <Input
                                name="title"
                                type="text"
                                // value={event ? event.title : ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title && validation.errors.title ? true : false
                                }
                              />
                              {validation.touched.title && validation.errors.title ? (
                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                              ) : null}
                            </Col>
                            <Col className="col-12 mb-3">
                              <Label className="form-label">Select Category</Label>
                              <Input
                                type="select"
                                name="category"
                                // value={event ? event.category : "bg-primary"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.category || ""}
                                invalid={
                                  validation.touched.category && validation.errors.category ? true : false
                                }
                              >
                                <option value="bg-danger">Danger</option>
                                <option value="bg-success">Success</option>
                                <option value="bg-primary">Primary</option>
                                <option value="bg-info">Info</option>
                                <option value="bg-dark">Dark</option>
                                <option value="bg-warning">Warning</option>
                              </Input>
                              {validation.touched.category && validation.errors.category ? (
                                <FormFeedback type="invalid">{validation.errors.category}</FormFeedback>
                              ) : null}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="button"
                                  className="btn btn-light me-2"
                                  onClick={toggle}
                                >
                                  Close
                                </button>
                                {!!isEdit && (
                                  <button
                                    type="button"
                                    className="btn btn-danger me-2"
                                    onClick={() => setDeleteModal(true)}
                                  >
                                    Delete
                                  </button>
                                )}
                                <button
                                  type="submit"
                                  className="btn btn-success save-event"
                                >
                                  Save
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <CreateUpdateSchedule modal={modalCreate} toggle={toggleCreate} handleCreateModal={handleCreateModal} data={modalData} modalDate={modalDate} userId={userId} setSchedules={setSchedules} refetch={refetch} />
      <CreateUpdateEvent modal={modalEvent} toggle={toggleEvent} handleCreateModal={handleEventModal} data={modalData} modalDate={modalDate} userId={userId} setSchedules={setSchedules} refetch={refetch} />
    </React.Fragment>
  );
};

Schedule.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
};

export default withRouter(React.memo(Schedule));
