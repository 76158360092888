import { del, get } from 'helpers/api_helper';
import { formatDate, getRole, getUserRole } from 'helpers/util';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Pagination } from 'react-laravel-paginex';
import { MetaTags } from 'react-meta-tags';
import Breadcrumbs from "components/Common/Breadcrumb";
import { Badge, Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledButtonDropdown, UncontrolledDropdown } from 'reactstrap';
import CreateUpdateStaff from './CreateUpdateStaff';
import DeleteConfirmation from 'components/Alert/DeleteConfirmation';
import ResetPassword from './ResetPassword';
import { useQuery } from '@tanstack/react-query';
import api from 'helpers/api';

const Staff = () => {
  const [id, setId] = useState('')
  const [inactive, setInactive] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false);
  const [modalReset, setModalReset] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [modalResetData, setModalResetData] = useState(null)
  const [showDelete, setShowDelete] = useState(false)
  const { data, refetch: getData, isLoading } = useQuery(['staff', search, page, inactive], () => api.getStaff({
    params: {
      page: page,
      search: search,
      inactive: inactive,
    }
  }), {
    refetchOnWindowFocus: false,
  });

  const deleteAction = async () => {
    const staffs = await del(`/staffs/${id}`);
    setShowDelete(false)
    getData();
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const toggleReset = () => {
    if (modalReset) {
      setModalReset(false);
    } else {
      setModalReset(true);
    }
  };

  const handleCreateModal = () => {
    toggle();
    setModalData(null)
  };

  const handleUpdateModal = (data) => {
    toggle();
    setModalData(data)
  };

  const handleResetModal = (data) => {
    toggleReset();
    setModalResetData(data)
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: data?.total, // replace later with size(staffs),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "id",
      staff: "desc",
      order: 'desc',
    },
  ];

  const EcommerceStaffColumns = toggleModal => [
    {
      dataField: "fullname",
      text: "Staff",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "role_id",
      text: "Roles",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => getUserRole(row.role_id),
    },
    {
      dataField: "created_at",
      text: "Join Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => formatDate(row.created_at),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-${row.status == 'ACTIVE' ? 'success' : 'danger'}`}
          color={`font-size-12 badge-soft-${row.status == 'ACTIVE' ? 'success' : 'danger'}`}
          pill
        >
          {row.status}
        </Badge>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>
              Action
            </DropdownToggle>
            <DropdownMenu>
              {(getRole() == 'Admin' || getRole() == 'Staff') && (
                <DropdownItem onClick={() => handleUpdateModal(row)}>
                  Edit
                </DropdownItem>
              )}
              {getRole() == 'Admin' && (
                <DropdownItem onClick={() => {
                  setId(row.id);
                  setShowDelete(true);
                }}>
                  Delete
                </DropdownItem>
              )}
              {getRole() == 'Admin' && (
                <DropdownItem onClick={() => handleResetModal(row)}>
                  Reset Password
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ADHD Centre</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Staff" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="2">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            placeholder="Search..."
                            className="form-control"
                            onInput={(e) => {
                              setSearch(e.target.value);
                            }}
                            value={search}
                            type="text"
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="d-flex">
                      <div className="form-check ms-2">
                        <input
                          id="defaultCheck1"
                          className="form-check-input"
                          type="checkbox"
                          value={inactive}
                          onChange={() => setInactive(prev => !prev)}
                        />
                        <label className="form-check-label" htmlFor="defaultCheck1">
                          Inactive
                        </label>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={() => handleCreateModal()}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add New Staff
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    <Row>
                      <Col sm="12">
                          {data && (
                            <PaginationProvider
                              pagination={paginationFactory(pageOptions)}
                              keyField="id"
                              columns={EcommerceStaffColumns()}
                              data={data.data}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="id"
                                  data={data.data}
                                  columns={EcommerceStaffColumns()}
                                  bootstrap4
                                  search
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField="id"
                                              responsive
                                              bstaffed={false}
                                              striped={false}
                                              defaultSorted={defaultSorted}
                                              selectRow={selectRow}
                                              classes={
                                                "table align-middle table-nowrap table-check"
                                              }
                                              headerWrapperClasses={"table-light"}
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="align-items-md-center mt-30">
                                        <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                          <Pagination changePage={(e) => setPage(e.page)} data={data} />
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          )}
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CreateUpdateStaff modal={modal} toggle={toggle} handleCreateModal={handleCreateModal} data={modalData} refresh={getData} />
      <ResetPassword modal={modalReset} toggle={toggleReset} handleCreateModal={handleResetModal} data={modalResetData} refresh={getData} />
      <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} />

    </React.Fragment>
  )
}

export default React.memo(Staff);