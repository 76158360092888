import React, { useState } from 'react'
import { Col, Label, Row, Spinner } from 'reactstrap'
import PropTypes from "prop-types";
import Select from "react-select";
import { useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { useFormContext } from 'react-hook-form';

const Doctor = ({data, refetch, errors}) => {
  const { setValue } = useFormContext();
  const [search, setSearch] = useState('')
  const [searchSite, setSearchSite] = useState('')
  const [searchGp, setSearchGp] = useState('')
  const { data: doctor, isLoading } = useQuery(['get-doctors', search], () => api.getSelectDoctor({
    params: {
      search: search,
    }
  }), {
    refetchOnWindowFocus: false
  });

  const { data: sites, isLoading: loadingSite } = useQuery(['get-sites', searchSite], () => api.getSelectSite({
    params: {
      search: searchSite,
    }
  }), {
    refetchOnWindowFocus: false
  });

  const { data: gp, isLoading: loadingGpSite } = useQuery(['get-gp', searchGp], () => api.getSelectGp({
    params: {
      search: searchGp,
    }
  }), {
    refetchOnWindowFocus: false
  });

  return (
    isLoading || loadingSite || loadingGpSite ? (
      <Spinner className="ms-2 spinner-loading" color="success" />
    ) : (
      <Row>
        <Col md={4}>
          <div className="mb-3">
            <Label htmlFor="formrow-doctor-Input">Doctor</Label>
            <Select
              maxMenuHeight={370}
              options={doctor}
              isMulti
              autoComplete="off"
              onInputChange={(e) => setSearch(e)}
              onChange={(e) => setValue('doctor', e.map(val => val.value))}
              isLoading={isLoading}
              classNamePrefix="select2-selection"
              defaultValue={doctor && doctor?.filter(
                doctor => data.doctors.find(userDoctor => doctor.value == userDoctor.id)
              )}
            />
            {errors?.doctor && <span className="form-text text-danger">{errors?.doctor[0]}</span>}
          </div>
        </Col>

        <Col md={4}>
          <div className="mb-3">
            <Label htmlFor="formrow-site-Input">Sites</Label>
            <Select
              options={sites?.sites}
              isMulti
              autoComplete="off"
              onChange={(e) => setValue('sites', e.map(val => val.value))}
              defaultValue={sites && sites?.sites?.filter(
                site => data.user.sites.find(userSite => site.value == userSite.id)
              )}
            />
            {errors?.sites && <span className="form-text text-danger">{errors?.sites[0]}</span>}
          </div>
        </Col>

        <Col md={4}>
          <div className="mb-3">
            <Label htmlFor="formrow-site-Input">General Practice</Label>
            <Select
              options={gp}
              autoComplete="off"
              onChange={(e) => setValue('general_practice_id', e?.value)}
              defaultValue={data?.general_practice_id && gp ? gp?.find(item => item?.value == data.general_practice_id) : null}
                isClearable
            />
            {errors?.general_practice_id && <span className="form-text text-danger">{errors?.general_practice_id[0]}</span>}
          </div>
        </Col>
      </Row>
    )
  )
}

Doctor.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  errors: PropTypes.object,
};

export default React.memo(Doctor)