import { useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { download } from 'helpers/api_helper';
import React, { useCallback, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { MetaTags } from 'react-meta-tags'
import { Button, Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap'
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ReportTimesheet = () => {
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [week, setWeek] = useState(null)
  const [series, setSeries] = useState([{
    data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
  }, ])
  const [options, setOptions] = useState({
    labels: ["Working Days", "Sick Leave", 'Day Off', 'Annual Leave'],
    chart: {
      foreColor: '#fff'
    },
    theme: {
      mode: 'dark',
      monochrome: {
        enabled: true,
        color: '#5653FE',
        shadeTo: 'dark',
        shadeIntensity: 0.65
      },
    },
    title: {
      text: 'Timesheet Report',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
  })

  const { data, refetch, isLoading } = useQuery(['get-report-timesheet', week], () => api.getReportTimesheet({
    params: {
      week,
    }
  }), {
    onSuccess: (res) => {
      const labels = res.timesheet.map(item => item.fullname);
      const workHours = res.timesheet.map(item => item.timesheet_total_hours);
      const breakHours = res.timesheet.map(item => item.timesheet_total_break_hours);
      setSeries([
        {
          name: 'Work Hours',
          data: workHours,
        },
        {
          name: 'Break Hours',
          data: breakHours,
        }
      ])
      // let option = options;
      // option.labels = labels;
      setOptions(prev => { return { ...prev, labels } })
    },
    enabled: !!week
  });

  const handleDownload = async () => {
    setLoadingDownload(true)
    await download('report-timesheet', 'Timesheet Report - ADHD Centre.pdf', {
      responseType: 'blob',
      params: {
        week,
      }
    })
    setLoadingDownload(false)
  }

  const handleDate = useCallback((val) => {
    setWeek(val);
  }, []);


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ADHD Centre</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Report Timesheet" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="d=flex justify-content-between mb-3">
                    <Col sm="2">
                      <input type="week" className="form-control" onChange={e => handleDate(e.target.valueAsDate)} />
                    </Col>
                    <Col sm="2" className="text-end">
                      <Button color="primary" onClick={handleDownload} disabled={loadingDownload == true}>
                        {loadingDownload ? (
                          <>
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                            Loading
                          </>
                        ) : (
                          <span>Download Report</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                  {isLoading && week ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    week ? (
                      <Row>
                        <Col>
                          <div id="donut-chart">
                            <ReactApexChart
                              series={series}
                              options={options}
                              type="bar"
                              height={800}
                              className="apex-charts"
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : null
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default React.memo(ReportTimesheet)