import React from 'react'
import PropTypes from "prop-types";
import { Modal } from 'reactstrap';

const Preview = ({ data, toggle, modal, handleCreateModal }) => {

  return (
    <Modal
      size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
      contentClassName="h-auto"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data?.subject}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <table
          className="body-wrap"
          style={{
            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
            boxSizing: "border-box",
            fontSize: "14px",
            width: "100%",
            backgroundColor: "#f6f6f6",
            margin: "0",
          }}
        >
          <tbody>
            <tr
              style={{
                fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                boxSizing: "border-box",
                fontSize: "14px",
                margin: "0",
              }}
            >
              <td
                style={{
                  fontFamily:
                    "Helvetica Neue,Helvetica,Arial,sans-serif",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  verticalAlign: "top",
                  margin: "0",
                }}
                valign="top"
              ></td>
              <td
                className="container"
                width="600"
                style={{
                  fontFamily:
                    "Helvetica Neue,Helvetica,Arial,sans-serif",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  verticalAlign: "top",
                  display: "block",
                  maxWidth: "600px",
                  clear: "both",
                  margin: "0 auto",
                }}
                valign="top"
              >
                <div
                  className="content"
                  style={{
                    fontFamily:
                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                    boxSizing: "border-box",
                    fontSize: "14px",
                    maxWidth: "600px",
                    display: "block",
                    margin: "0 auto",
                    padding: "20px",
                  }}
                >
                  <table
                    className="main"
                    width="100%"
                    cellPadding="0"
                    cellSpacing="0"
                    style={{
                      fontFamily:
                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      borderRadius: "7px",
                      backgroundColor: "#fff",
                      margin: "0",
                      boxShadow: "0 0.75rem 1.5rem rgba(18,38,63,.03)",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          fontFamily:
                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          margin: "0",
                        }}
                      >
                        <td
                          className="alert alert-warning"
                          style={{
                            fontFamily:
                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                            boxSizing: "border-box",
                            fontSize: "16px",
                            verticalAlign: "top",
                            color: "#fff",
                            fontWeight: "500",
                            textAlign: "center",
                            borderRadius: "3px 3px 0px 0px",
                            backgroundColor: "#556ee6",
                            margin: "0",
                            padding: "20px",
                          }}
                          align="center"
                          valign="top"
                        >
                          My Adhd Centre.
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily:
                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          margin: "0",
                        }}
                      >
                        <td
                          className="content-wrap"
                          style={{
                            fontFamily:
                              "Helvetica Neue,Helvetica,Arial,sans-serif",
                            boxSizing: "border-box",
                            fontSize: "14px",
                            verticalAlign: "top",
                            margin: "0",
                            padding: "20px",
                          }}
                          valign="top"
                        >
                          <table
                            width="100%"
                            cellPadding="0"
                            cellSpacing="0"
                            style={{
                              fontFamily:
                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                              boxSizing: "border-box",
                              fontSize: "14px",
                              margin: "0",
                            }}
                          >
                            <tbody>
                              <tr
                                style={{
                                  fontFamily:
                                    "Helvetica Neue,Helvetica,Arial,sans-serif",
                                  boxSizing: "border-box",
                                  fontSize: "14px",
                                  margin: "0",
                                }}
                              >
                                <td
                                  className="content-block"
                                  style={{
                                    fontFamily:
                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                    boxSizing: "border-box",
                                    fontSize: "14px",
                                    verticalAlign: "top",
                                    margin: "0",
                                    padding: "0 0 20px",
                                  }}
                                  valign="top"
                                >
                                  <div dangerouslySetInnerHTML={{ __html: data?.text }}></div>
                                </td>
                              </tr>

                              <tr
                                style={{
                                  fontFamily:
                                    "Helvetica Neue,Helvetica,Arial,sans-serif",
                                  boxSizing: "border-box",
                                  fontSize: "14px",
                                  margin: "0",
                                }}
                              >
                                <td
                                  className="content-block"
                                  style={{
                                    textAlign: "center",
                                    fontFamily:
                                      "Helvetica Neue,Helvetica,Arial,sans-serif",
                                    boxSizing: "border-box",
                                    fontSize: "14px",
                                    verticalAlign: "top",
                                    margin: "0",
                                    padding: "0",
                                  }}
                                  valign="top"
                                >
                                  © {new Date().getFullYear()} My Adhd Centre
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  )
}

Preview.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default Preview;